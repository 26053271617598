import styled from 'styled-components';
import { Button as MuiButton } from '@mui/material';
import { spacing } from '@mui/system';
import Colors from '../utils/helper/Colors';

const ButtonPre = styled(MuiButton)`
  color: ${(props) => {
    switch (props.color) {
      case 'default':
        return Colors.keepoala.greytext;
      case 'primary':
        return Colors.keepoala.greytext;
      case 'secondary':
        return Colors.keepoala.actionGreenlight;
      case 'disabled':
        return Colors.keepoala.greymedium;
      default:
        return Colors.keepoala.black;
    }
  }} !important;
  background-color: ${(props) => {
    switch (props.color) {
      case 'default':
        return '#ffffff';
      case 'primary':
        return Colors.keepoala.actionGreendefault;
      case 'secondary':
        return Colors.keepoala.keepoalaGreendark;
      case 'disabled':
        return '#ffffff';
      default:
        return '#ffffff';
    }
  }} !important;
  text-transform: uppercase !important;
  font-family: Inter !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  min-height: 46px !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
    height: 34px !important;
  }
  &:hover {
    background-color: ${(props) => {
      switch (props.color) {
        case 'default':
          return Colors.keepoala.dark;
        case 'primary':
          return Colors.keepoala.actionGreendark;
        case 'secondary':
          return Colors.keepoala.forestGreendefault;
        default:
          return Colors.keepoala.grey;
      }
    }} !important;
    color: ${(props) => {
      switch (props.color) {
        case 'default':
          return '#ffffff';
        case 'primary':
          return Colors.keepoala.greytext;
        case 'secondary':
          return Colors.keepoala.actionGreenlight;
        case 'disabled':
          return Colors.keepoala.greymedium;
        default:
          return Colors.keepoala.darktext;
      }
    }} !important;
  }
  &:disabled {
    background-color: #eee !important;
    color: #000 !important;
  }
`;
const Button = styled(ButtonPre)(spacing);
export default Button;

export const FacebookButton = styled(Button)`
  background-color: #4460a0;
  color: #ffffff;
  &:hover {
    background-color: ${Colors.keepoala.grey};
    color: #4460a0;
  }
`;

export const AppleButton = styled(Button)`
  background-color: ${Colors.keepoala.black};
  color: #ffffff;
  &:hover {
    background-color: ${Colors.keepoala.grey};
    color: ${Colors.keepoala.black};
  }
`;
