import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import firebase from '../firebase';
import { toast } from 'react-toastify';
import Button from '../styles/Button';


const Container = styled.div`
    min-height: calc(100vh - 64px - 80px);
    background-color: #F5F5F5;
    padding: 40px;
    display: flex;
    justify-content: center;
`;

const GreenTextField = styled(TextField)`
  & label.Mui-focused {
    color: #2F8481;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid #2F8481;
  }
`;

const GreenSelectContainer = styled(FormControl)`
  & label.Mui-focused {
    color: #2F8481;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid #2F8481;
  }
`;


function VoucherSend() {
    /**
     * State Variables 
     */

    const db = firebase.firestore();
    const [vouchers, setVouchers] = useState([]);
    const [selectedVoucher, setSelectedVoucher] = useState("");
    const [code, setCode] = useState("");
    const [disableSave, setDisableSave] = useState(false);


    // Get data on mount
    useEffect(() => {
        db.collection('Orders').where('type', '==', 'voucher').where("datenum",">",1664409600).get()
            .then((querySnapshot) => {
                const tempVouchers = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (!data.tracking_numbers || data.tracking_numbers.length === 0) {
                        tempVouchers.push({
                            name: data.name,
                            id: doc.id,
                            datenum: data.datenum
                        });
                    }
                });
                // Receive all officially sent out vouchers
                db.collection("VoucherSend").get().then((all_sent_voucher_docs) => {

                    // get all IDs of offically sent out vouchers
                    const all_sent_ids = all_sent_voucher_docs.docs.map((voucher_doc) => {
                        const voucher_sent_doc_data = voucher_doc.data();
                        if (voucher_sent_doc_data?.sent === true) {
                            return voucher_sent_doc_data.order;
                        } else {
                            return undefined;
                        }
                    }).filter((x) => (x!==undefined));

                    // Remove all officially sent out vouchers from collection
                    const tempVouchers_filtered = tempVouchers
                    .filter((x) => !(all_sent_ids.includes(x.id)))
                    .filter((x) => (!/gewinnspiel/.test(x.name)))
                    .sort((a,b) => (a.datenum - b.datenum))
                    setVouchers(tempVouchers_filtered);
                })
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }, [db]);

    const sendVoucher = async (event) => {
        event.preventDefault();

        const FormObject = {
            order: selectedVoucher,
            code
        };

        try {
            // Disable the button till finished
            setDisableSave(true);
            toast.info('Voucher sending...', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
            });

            const documentRef = db.collection('VoucherSend').doc();
            await documentRef.set(FormObject);

            const unsubscribe = documentRef.onSnapshot((doc) => {
                const data = doc.data();
                if (data.sent === true) {
                    // Send a notification
                    toast.success('Voucher set to Sent Successfully', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                    });

                    // Remove the voucher from the list and clear the form
                    const filteredVouchers = vouchers.filter((voucher) => voucher.id !== selectedVoucher);
                    setVouchers([...filteredVouchers]);
                    setSelectedVoucher("");
                    setCode("");

                    // Unsubscribe from the listener
                    unsubscribe();
                    // Enable the save button again
                    setDisableSave(false);
                }
            });
        } catch (e) {
            console.error(e);
            // Send a notification
            toast.error('Failed to Create Voucher Partner, Check the console for the error details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
            });
            setDisableSave(false);
        }
    }

    return (
        <Container>
            <Box sx={{ width: 800 }}>
                <Card variant="outlined" sx={{ padding: 3 }}>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
                        Send Voucher
                    </Typography>

                    <form style={{ marginTop: '20px' }} onSubmit={sendVoucher}>
                        <GreenSelectContainer variant="filled" sx={{ width: '100%', marginBottom: 3 }}>
                            <InputLabel>Voucher</InputLabel>
                            <Select
                                value={selectedVoucher}
                                required
                                onChange={(event) => setSelectedVoucher(event.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '300px'
                                        },
                                    },
                                }}
                            >
                                <MenuItem value=""></MenuItem>
                                {vouchers.map((voucher) => {
                                    console.log(voucher.datenum)
                                    const order_date = new Date(parseFloat(voucher.datenum) * 1000);
                                    
                                    return <MenuItem
                                        style={{ display: "block", padding: '5px 10px', fontFamily: 'Inter' }}
                                        key={voucher.id} value={voucher.id}>{voucher.name} - {voucher.id} <br/> {order_date.toISOString()}
                                    </MenuItem>
                                }
                                )}
                            </Select>
                        </GreenSelectContainer>
                        <GreenTextField required label="Code" value={code}
                            onChange={(event) => setCode(event.target.value)}
                            variant="filled" fullWidth sx={{ marginBottom: 3 }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ width: '50%' }} color="primary" disabled={disableSave} type="submit">
                                Save
                            </Button>
                        </div>
                    </form>
                </Card>
            </Box>
        </Container>
    )
}

export default VoucherSend;


