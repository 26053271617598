import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const db = firebase.firestore();
  // try {db.settings({
  //     host: "localhost:8090",
  //     ssl: false
  // });} catch {
  //     console.log("Cannot init")
  // }
  // db.settings({
  //     host: '192.168.178.31:8090',
  //     ssl: false,
  //     persistence: false,
  //   }).then(() => console.log('invoke - 192.168.178.31:8090'));

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoadingAuthState(true);
      if (user) {
        setLoadingAuthState(false);
      } else {
        setLoadingAuthState(false);
      }
    });
  }, [db]);

  const handleSignIn = async (username, password) => {
    try {
      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(username, password);
      console.log('Im signed in,', response);
      return {
        status: true,
        data: response,
      };
    } catch (error) {
      return {
        status: false,
        data: error.message,
      };
    }
  };

  const handleSignOut = () => {
    setLoadingAuthState(true);
    firebase
      .auth()
      .signOut()
      .then((res) => {
        console.log('Im signed out,', res);
        setLoadingAuthState(false);
      })
      .catch((err) => {
        console.error(err.message);
        setLoadingAuthState(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        handleSignIn,
        handleSignOut,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
