import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { CSVLink } from 'react-csv';
import Divider from '@mui/material/Divider';
import { Box, TablePagination, TextField } from '@mui/material';
import Item from '../components/Item';
import ShopOrderList from '../components/ShopOrderList';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function ShopOrders(props) {
  const db = firebase.firestore();

  const [data, setData] = useState([]);
  const [dataRows, setDataRows] = useState(0);
  const [dataRowsStore, setDataRowsStore] = useState(0);
  const [downloadData, setDownloadData] = useState([]);
  const [lineItemData, setLineItemData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [pageChange, setPageChange] = useState('none');
  const [lastDoc, setLastDoc] = useState(null);
  const [startDoc, setStartDoc] = useState(null);
  const [loading, setLoading] = useState(null);
  const [dateRangeStart, setDateRangeStart] = useState(
    new Date(Date.now() - 180 * 24 * 60 * 60 * 1000),
  );
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date(Date.now()));

  function change_query(start_date, end_date) {
    const converted_start = start_date.getTime() / 1000;
    const converted_end = end_date.getTime() / 1000;
    const query = db
      .collection(props.collection)
      .where(
        props.collection == 'User' ? 'registration_datenum' : 'datenum',
        '>=',
        converted_start,
      )
      .where(
        props.collection == 'User' ? 'registration_datenum' : 'datenum',
        '<=',
        converted_end,
      )
      .orderBy(
        props.collection == 'User' ? 'registration_datenum' : 'datenum',
        'desc',
      );
    // increase Page
    if (pageChange == 'increase') {
      return query.limit(rowsPerPage).startAt(lastDoc);
      // stay on page change rows
    } else if (
      (pageChange === 'none' || pageChange === 'rows_only') &&
      startDoc !== undefined &&
      startDoc !== null
    ) {
      return query.limit(rowsPerPage).startAt(startDoc);
      // decrease page
    } else {
      if (page == 0) {
        return query.limit(rowsPerPage);
      } else {
        return query.limit(rowsPerPage).endAt(startDoc);
      }
    }
  }

  const handleChangePage = (event, pageinput) => {
    event.preventDefault();
    if (pageinput > page) {
      setPageChange('increase');
    } else if (page > pageinput) {
      setPageChange('decrease');
    }
    setPage(pageinput);
    // setPage(pageinput)
  };
  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPageChange('rows_only');
  };

  function get_data_rows() {
    db.collection('KeepoalaAggregation')
      .doc('aggregation')
      .get()
      .then((ag_doc) => {
        const ag_doc_data = ag_doc.data();
        setDataRows(ag_doc_data[props.collection]);
        setDataRowsStore(ag_doc_data[props.collection]);
      });
  }
  function get_data(query) {
    query.get().then((shoporder_snap) => {
      const all_orders = shoporder_snap.docs.map(async (order_snap) => {
        const order_data = order_snap.data();

        let ordered;
        if (props.collection == 'User') {
          let email_string = '';
          await db
            .collection(props.collection)
            .doc(order_snap.id)
            .collection('emails')
            .get()
            .then((email_snaps) => {
              email_snaps.forEach((email_snap) => {
                const email_data = email_snap.data();
                email_string = email_string + email_data.email + ',';
              });
            });
          console.log(email_string);
          order_data.email = email_string;
          ordered = order_data;
        } else {
          ordered = Object.keys(order_data)
            .sort()
            .reduce((obj, key) => {
              obj[key] = order_data[key];
              return obj;
            }, {});
        }

        return {
          ...ordered,
          key: order_snap.id,
        };
      });
      setStartDoc(shoporder_snap.docs[0]);
      setLastDoc(shoporder_snap.docs[shoporder_snap.docs.length - 1]);
      Promise.all(all_orders).then((resolved_orders) => {
        if (resolved_orders.length < rowsPerPage) {
          setDataRows(resolved_orders.length);
        } else {
          setDataRows(dataRowsStore);
        }
        setData(resolved_orders);
        setLoading(false);
        setDownloadData(
          resolved_orders.map((value) => {
            return Object.keys(value)
              .sort()
              .filter((key) => props.allowed.includes(key))
              .reduce((obj, key) => {
                if (key === 'total_price') {
                  obj[key] = value[key].toString();
                } else {
                  obj[key] = value[key];
                }
                return obj;
              }, {});
          }),
        );
        if (resolved_orders.length > 0) {
          setLineItemData(
            resolved_orders
              .map((value) => {
                if (value.line_items !== undefined) {
                  return value.line_items.map((obj) => {
                    obj['order_id'] = value.id;
                    obj['shop'] = value.shop;
                    return obj;
                  });
                } else {
                  return [];
                }
              })
              .flat(),
          );
        }
      });
    });
  }

  useEffect(() => {
    if (dataRowsStore == 0) {
      get_data_rows();
    } else {
      setLoading(true);
      get_data(change_query(dateRangeStart, dateRangeEnd));
    }
  }, [dataRowsStore, pageChange, dateRangeEnd, dateRangeStart]);

  return (
    <div style={{ margin: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Item>
          {' '}
          <CSVLink data={downloadData}>
            {'Download ' + props.collection + '(without items)'}
          </CSVLink>
        </Item>
        <Item>
          {' '}
          <CSVLink data={lineItemData}>{'Download (items)'}</CSVLink>
        </Item>
      </Box>
      <Divider style={{ margin: 20 }}></Divider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label='From'
          variant='inline'
          format='MM/dd/yyyy'
          margin='normal'
          id='date-picker-inline'
          value={dateRangeStart}
          onChange={(value) => {
            setDateRangeStart(value);
          }}
          maxDate={Date.now()}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
          label='To'
          variant='inline'
          format='MM/dd/yyyy'
          margin='normal'
          id='date-picker-inline'
          value={dateRangeEnd}
          onChange={(value) => {
            setDateRangeEnd(value);
          }}
          maxDate={Date.now()}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <TablePagination
        component='div'
        count={dataRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100, 200, 300, 500, 1000]}
      />
      {data.length < 1 || loading ? (
        'loading'
      ) : (
        <ShopOrderList data={data} header={props.header} />
      )}
    </div>
  );
}

ShopOrders.defaultProps = {
  allowed: [
    'shop',
    'name',
    'created_at',
    'country',
    'email',
    'moved',
    'total_price',
    'currency',
    'date_return_max',
    'fullfillment_status',
    'id',
    'note',
    'note_attributes',
    'number',
    'returned',
    'subtotal_price',
    'total_discounts',
    'total_tax',
  ],
  collection: 'shoporders',
  header: [
    'shop',
    'name',
    'created_at',
    'country',
    'email',
    'moved',
    'total_price',
    'currency',
    'line_items',
    'customer',
    'datenum',
    'date_return_max',
    'fullfillment_status',
    'id',
    'note',
    'note_attributes',
    'number',
    'returned',
    'subtotal_price',
    'total_discounts',
    'total_shipping_price_set',
    'total_tax',
  ],
};
