import React from 'react';
import Colors from '../utils/helper/Colors';

type transformType = 'uppercase' | 'lowercase' | 'none';
type sizeType =
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 's'
  | 'xs'
  | 'xsl'
  | 'xxs'
  | 'title';
type typeType =
  | 'black'
  | 'extra-bold'
  | 'bold'
  | 'semi-bold'
  | 'medium'
  | 'regular'
  | 'light'
  | 'extra-light'
  | 'thin';

type ColorType =
  | 'main'
  | 'white'
  | 'actionGreenlight'
  | 'keepoalaGreendefault'
  | 'keepoalaGreendark'
  | 'forestGreendefault'
  | 'greytext'
  | 'greydefault'
  | 'mediumgrey'
  | 'light'
  | 'bright'
  | 'dark'
  | 'darktext'
  | 'lightgrey'
  | 'grey'
  | 'greengrey'
  | 'black'
  | 'transparent';

type InterTextProps = {
  children: any;
  className?: any;
  size?: sizeType;
  align?: string;
  color: ColorType;
  transform?: transformType;
  type?: typeType;
  style?: any;
};
/**
 * Inter Font text with different styles
 * @param props
 * @returns
 */
export default function InterText(props: InterTextProps) {
  const { children, className, size, align, color, transform, type, style } =
    props;

  let fontSize: number;
  let fontWeight: number;

  switch (size) {
    case 'xxs':
      fontSize = 8;
      break;
    case 'xsl':
      fontSize = 11;
      break;
    case 'xs':
      fontSize = 12;
      break;
    case 's':
      fontSize = 14;
      break;
    case 'm':
      fontSize = 16;
      break;
    case 'l':
      fontSize = 20;
      break;
    case 'xl':
      fontSize = 24;
      break;
    case 'xxl':
      fontSize = 36;
      break;
    case 'xxxl':
      fontSize = 48;
      break;
    case 'title':
      fontSize = 60;
      break;
    default:
      fontSize = 12;
      break;
  }

  switch (type) {
    case 'black':
      fontWeight = 900;
      break;
    case 'extra-bold':
      fontWeight = 800;
      break;
    case 'bold':
      fontWeight = 700;
      break;
    case 'extra-light':
      fontWeight = 200;
      break;
    case 'light':
      fontWeight = 300;
      break;
    case 'medium':
      fontWeight = 500;
      break;
    case 'regular':
      fontWeight = 400;
      break;
    case 'semi-bold':
      fontWeight = 600;
      break;
    case 'thin':
      fontWeight = 100;
      break;
    default:
      fontWeight = 400;
      break;
  }

  var font = {
    fontFamily: 'Inter',
    fontSize: fontSize,
    fontWeight: fontWeight,
    color: Colors.keepoala[color],
    textAlign: align,
    textTransform: transform,
  };
  return (
    <span style={{ ...font, ...style }} className={className}>
      {children}
    </span>
  );
}

InterText.defaultProps = {
  transform: 'none',
  color: 'greytext',
  align: 'auto',
  size: 'm',
  type: 'regular',
};
