import React from 'react';
import Image from "react-image-enlarger";


export default function ZoomImage({ src }) {
    const [zoomed, setZoomed] = React.useState(false);
  
    return (
      <div style={{ margin: "0.25rem" }}>
        <Image
          style={{ width: "30px", height: "auto" }}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    );
  }
  