import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';
import {
  dataTableStyle,
  headerStyle,
  stableSort,
  getComparator,
  descendingComparator,
} from './OrderList';
import ShopOrderListRow from './ShopOrderListRow';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default function ShopOrderList({ data, header }) {
  const cannot_sort = ['customer', 'line_items', 'total_shipping_price_set'];
  const is_date = ['date_return_max', 'datenum', 'registration_datenum'];
  const [order, setOrder] = React.useState('keep_status_days');
  const [orderBy, setOrderBy] = React.useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer plain='true' style={dataTableStyle}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            {header.map((label, index) => (
              <TableCell style={headerStyle} key={index} align={'left'}>
                {label}
                {label !== '' && !cannot_sort.includes(label) ? (
                  <TableSortLabel
                    active={orderBy === label}
                    direction={orderBy === label ? order : 'asc'}
                    onClick={createSortHandler(label)}
                  >
                    {orderBy === label ? (
                      <span
                        style={{
                          border: 0,
                          clip: 'rect(0 0 0 0)',
                          height: 1,
                          margin: -1,
                          overflow: 'hidden',
                          padding: 0,
                          position: 'absolute',
                          top: 20,
                          width: 1,
                        }}
                      >
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy)).map(
            (order, index) => (
              <ShopOrderListRow
                key={index}
                data={order}
                needs_expansion={cannot_sort}
                is_date={is_date}
                header={header}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
