import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import firebase from '../firebase';
import { toast } from 'react-toastify';
import InterStyled from '../styles/InterStyled';
import Colors from '../utils/helper/Colors';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
const Container = styled.div`
  min-height: 93vh;
  background-color: #f5f5f5;
  padding: 40px;
  display: flex;
  justify-content: center;
`;
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function PushNotifications() {
  /**
   * State Variables
   */

  const db = firebase.firestore();

  // UI Controlling Variables
  const [icon, setIcon] = useState("no");
  const [selected, setSelected] = useState("keepoalatesting");
  const [filteration, setFilteration] = useState(false);
  const [dynamicLink, setDynamicLink] = useState(false);
  const [activeFilter, setActiveFilter] = useState('no_voucher');
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [orderNum, setOrderNum] = useState(undefined);
  const [operator, setOperator] = useState("==");
  const [UIDLength, setUIDLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [notificationObject, setNotificationObject] = useState({});
  const [linkType, setLinkType] = useState("keep");
  const [shopifyID, setShopifyID] = useState();
  const [orderDocumentID, setOrderDocumentID] = useState();
  const [shopifyEmail, setshopifyEmail] = useState();
  const [shopifyName, setshopifyName] = useState();
  const [returnedID, setReturnedID] = useState();
  const [shopifyShop, setShopifyShop] = useState();
  const [loading, setLoading] = useState(true);
  const [loading_orders, setLoadingOrders] = useState(true);
  const [orders, setOrders] = useState([]);
  const [UIDs, setUIDs] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [rangeError, setRangeError] = useState(
    'If you want to set a certain number put the same number in both to and from.',
  );
  const [challengeError, setChallengeError] = useState(undefined);
  const [user_no_voucher, setUserNoVoucher] = useState([]);
  const [user_open_return_questions, setUserOpenReturnQuestions] = useState([]);
  const [user_open_product_questions, setUserOpenProductQuestions] = useState(
    []
  );
  const [user_orders, setUserOrders] = useState({});

  // Error Controlling variables
  const [uidError, setUIDError] = useState(
    "Comma seperated users ids ex: Edgf45D,EdR45,FER4p"
  );
  const [orderNumError, setOrderNumError] = useState("Add a number only");
  const [titleError, setTitleError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [formSubmitable, setFormSubmitable] = useState(true);

  /**
   * Validation Functions
   */

  const validateTitle = (ttl) => {
    // Regex: all characters limit to 24 one
    const valid = ttl.length > 0 && ttl.length < 36;
    if (valid) {
      setTitleError("");
      return true;
    } else if (!valid && ttl === "") {
      setTitleError("Required");
      return false;
    } else {
      setTitleError("Allowed Values are [a-zA-Z] limit to 24 characters only");
      return false;
    }
  };

  const validateUID = (id) => {
    // TODO: Add to this the right regex if needed
    let valid = /^[A-Za-z/=,0-9]+$/.test(id);
    if (valid) {
      setUIDError("Comma seperated users ids ex: Edgf45D,EdR45,FER4p");
      return true;
    } else if (!valid && id === "") {
      setUIDError("Required");
      return false;
    } else {
      setUIDError("Allowed Values are [a-zA-Z0-9/=] only");
      return false;
    }
  };

  const validateMessage = (msg) => {
    const valid = msg.length > 0 && msg.length < 300;
    if (valid) {
      setMessageError("");
      return true;
    } else {
      setMessageError("Exceeded more then 300 characters");
      return false;
    }
  };
  const validateOrderNum = (exp) => {
    const valid = /^[0-9]*$/.test(exp);
    if (valid) {
      setOrderNumError("Add a number only");
      setOrderNum(exp);
      return true;
    } else {
      setOrderNumError("Input needs to be numbers only");
      setOrderNum(undefined);
      return false;
    }
  };

 
  // submit the push notification to the REST endpoint
  const submitPushNotification = async () => {

    setLoadingOrders(true)
    if (notificationObject !== {}) {
      console.log("agreed");
      const req_body = JSON.stringify(notificationObject);
      const API_KEY =
        selected === "keepoala"
          ? process.env.REACT_APP_SERVER_KEY_LIVE
          : process.env.REACT_APP_SERVER_KEY_TEST;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-requested-with": "application/json",
          Signature:
            "sha256=" + Base64.stringify(hmacSHA256(req_body, API_KEY)),
        },
        body: req_body,
      };

      // "http://localhost:5001/keepoalatesting/europe-west1/push_notifications";
      const url = `https://europe-west1-${selected}.cloudfunctions.net:443/push_notifications`;

      fetch(url, requestOptions)
        .then((res) => {
          console.log(res);
          // Toast to success
          if (res.status === 404) {
            return res.json();
          } else {
            try {
              res.json().then((result) => {
                toast.success(
                  'Push Notification Created Successfully for ' +
                    result.success_count +
                    '/' +
                    result.requested_count,
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                  },
                );
              });
            } catch (e) {
              console.log(e);
              toast.success(
                'Push Notification Created Successfully, no details',
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                },
              );
            }
            return null;
          }

        })
        .then((result) => {
          if (result === null) {
            return;
          }
          toast.error('Failed to Create Push Notification, ' + result.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        })
        .catch((err) => {
          console.error(err);
          // Send a notification
          toast.error(
            "Failed to Create Push Notification, Check the console for the error details",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            }
          );
        }).finally(()=>{
          setLoadingOrders(false)
        });
      handleClose();
      return;
    }
    console.log("diagreed");
    handleClose();
    return;
  };
  function onValueChange(event) {
    setSelected(event.target.value);
  }
  const handleIconChange = (event) => {
    setIcon(event.target.value);
  };
  const handleFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };
  const handleChallengeSelection = (event) => {
    setSelectedChallenge(event.target.value);
  };
  const handleLinkTypeChange = (event) => {
    setLinkType(event.target.value);
    setNotificationObject({});
    setUIDLength(null);
    // resets notification objects
    if (event.target.value === "voucherorder") {
      setshopifyEmail();
      setshopifyName();
      setShopifyID();
      setShopifyShop();
      setReturnedID();
    } else {
      setOrderDocumentID();
    }
  };
  const handleClickOpen = async (event) => {
    event.preventDefault();
    setOpen(true);
    setNotificationObject({});
    setUIDLength(null);
    setLoading(true);

    // Return if another submit is in place
    if (!formSubmitable) return;

    // Stop submitting forms until finished with this submition
    setFormSubmitable(false);

    // Validation
    if (!filteration) {
      if (!validateUID(event.target.uids.value)) {
        setFormSubmitable(true);
        return;
      }
    }

    if (!validateTitle(event.target.title.value)) {
      setFormSubmitable(true);
      return;
    }
    if (!validateMessage(event.target.message.value)) {
      setFormSubmitable(true);
      return;
    }
    if (
      activeFilter === 'orders' &&
      filteration &&
      parseInt(event.target.from_number.value) >
        parseInt(event.target.to_number.value)
    ) {
      setFormSubmitable(true);
      setRangeError('To value needs to be bigger than or equal From value');
      setLoading(false);
      return;
    } else {
      setRangeError(
        'If you want to set a certain number put the same number in both to and from.',
      );
    }

    if (
      activeFilter === 'challenge' &&
      filteration &&
      selectedChallenge === ''
    ) {
      setFormSubmitable(true);
      setChallengeError('Please Select a challenge');
      return;
    }

    setOpen(true);

    let shopify = {};

    if (shopifyEmail && shopifyName && shopifyName) {
      shopify = {
        shopify: {
          id: shopifyID,
          email: shopifyEmail,
          name: shopifyName,
          shop: shopifyShop,
        },
      };
    }

    if (
      (linkType === 'voucherorder' || linkType === 'voucher') &&
      orderDocumentID !== undefined
    ) {
      shopify.order_document_id = orderDocumentID;
    }

    if (returnedID !== undefined) {
      shopify.id = returnedID;
    }

    if (dynamicLink) {
      if (shopify === {}) {
        return;
      }
    }

    console.log("Form Validation Passed");

    // initalizing uids
    let finalUID;
    // handling filter option if selected
    if (filteration) {
      let to_from = {to:0,from:0}
      if(event.target.to_number !== undefined && event.target.from_number !== undefined ){
        to_from={to: event.target.to_number.value, from: event.target.from_number.value}
      }
      finalUID = await getUserIdsFromFilter(activeFilter, {
        to: parseInt(to_from.to),
        from: parseInt(to_from.from),
      });
      console.log("this is the final uid: ", finalUID);
    } else {
      //checking if it's just a single uid turn it in to an array
      if (!event.target.uids.value.includes(",")) {
        finalUID = [event.target.uids.value];
      } else {
        finalUID = event.target.uids.value.split(",");
      }

      console.log("this is the uids: ", finalUID);
    }

    // Check if Final UID has something at the end
    if (finalUID === undefined) {
      toast.error("There are no user IDs to send them notifications!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      setLoading(false);
      setOpen(false);
      setNotificationObject({});
      setUIDLength(null);
      setFormSubmitable(true);
      return;
    }
    setUIDLength(finalUID.length);

    // Create notification object from the data
    console.log({
      title: event.target.title.value,
      uids: finalUID,
      message: event.target.title.value + "|" + event.target.message.value,
      db: selected,
      icon: icon === "no" ? undefined : icon,
      needed_params: dynamicLink ? shopify : undefined,
      type: dynamicLink ? linkType : undefined,
    });
    setNotificationObject({
      title: event.target.title.value,
      uids: finalUID,
      message: event.target.title.value + '|' + event.target.message.value,
      db: selected,
      icon: icon === "no" ? undefined : icon,
      needed_params: dynamicLink ? shopify : undefined,
      type: dynamicLink ? linkType : undefined,
    });
    setLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
    // Enable submitting the form again
    setFormSubmitable(true);
    console.log("notification Object", notificationObject);
  };
  const handleAgree = () => {
    submitPushNotification();
    // Enable submitting the form again
    setFormSubmitable(true);
    console.log("notification Object", notificationObject);
  };
  async function getUserIdsFromFilter(filter, parameter) {
    switch (filter) {
      // case where users do not have vouchers
      case "no_voucher":
        return user_no_voucher;
      // case where users didn't answer back the return questions
      case "return_questions":
        return user_open_return_questions;

      // case where users didn't answer their product questions
      case "product_questions":
        return user_open_product_questions;
        // case where users didn't do their orders
        case 'orders':
          if (parameter.to === undefined || parameter.from === undefined) {
            console.error('number of orders need to be defined!');
            toast.error('number of orders need to be defined!', {
              position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          return;
        } else {

          const user_id_list = [];
          for (const key in user_orders) {
            if (
              (user_orders[key] >= parameter.from &&
                user_orders[key] < parameter.to) ||
              (parameter.from === parameter.to &&
                parameter.from === user_orders[key])
            ) {
              user_id_list.push(key);
            }
          }
          console.log(user_id_list.length);
          return user_id_list.filter(onlyUnique);
        }
      case 'keepoalas':
        if (parameter.to === undefined || parameter.from === undefined) {
          console.error('number of orders need to be defined!');
          toast.error('number of orders need to be defined!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          return;
        } else {
          let query = db.collection('Aggregation');
          if (parameter.to === parameter.from) {
            query = query.where('keepoalas.keepoalas', '==', parameter.from);
          } else {
            query = query
              .where('keepoalas.keepoalas', '>=', parameter.from)
              .where('keepoalas.keepoalas', '<', parameter.to);
          }

          return await query
            .get()
            .then((querySnapshot) => {
              const user_id_list = [];

              querySnapshot.forEach((doc) => {
                user_id_list.push(doc.data().user_id);
              });

              console.log(user_id_list.length);
              return user_id_list.filter(onlyUnique);
            })
            .catch((err) => {
              console.error("error in fetching data", err);
              toast.error("Error in fetching data!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
              });
            });
        }
        case 'challenge':
          if (selectedChallenge === '') {
            console.error('A challenge needs to be selected!');
            toast.error('Select a challenge!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            });
            return;
          } else {
            return challenges.find(challenge => challenge.id === selectedChallenge).participants;
          }
      default:
        break;
    }
  }

  const getChallenges = async () => {
    const all_challenges = [];
    return db
        .collection('Challenges')
        .orderBy('end_date', 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            all_challenges.push(doc.data());
          });
          setChallenges(all_challenges);
        })
        .catch((err) => {
          console.error('error in fetching data', err);
          toast.error('Error in fetching data!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
  }

  // setting all orders at the initial page render
  useEffect( () => {

     // fetching users with no vouchers
  const fetchAggregationData = async () => {
    setLoadingOrders(true);

    await db
      .collection("Aggregation")
      .get()
      .then((querySnapshot) => {
        const uid_list_no_vouchers = [];
        const uid_list_open_return_questions = [];
        const uid_list_open_product_questions = [];
        const uid_list_users_orders = {};
        querySnapshot.forEach((doc) => {
          // if user has no vouchers
          if (doc.data().order_types !== undefined) {
            if (doc.data().order_types.vouchers !== undefined) {
              if (doc.data().order_types.vouchers === 0) {
                uid_list_no_vouchers.push(doc.data().user_id);
              }
            }
            if (doc.data().order_types.orders !== undefined) {
              uid_list_users_orders[doc.data().user_id] =
                doc.data().order_types.orders;
            }
          }
          if (doc.data().questions_open !== undefined) {
            if (doc.data().questions_open.keep !== undefined) {
              if (doc.data().questions_open.keep > 0) {
                uid_list_open_return_questions.push(doc.data().user_id);
              }
            }
            if (doc.data().questions_open.returned !== undefined) {
              if (doc.data().questions_open.returned > 0) {
                uid_list_open_product_questions.push(doc.data().user_id);
              }
            }
          }
        });

        getChallenges().then(() => {

          console.log(
            uid_list_no_vouchers.length,
            uid_list_open_return_questions.length,
            uid_list_open_product_questions.length
          );
          setUserNoVoucher(uid_list_no_vouchers.filter(onlyUnique));
          setUserOpenReturnQuestions(
            user_open_return_questions.filter(onlyUnique)
          );
          setUserOpenProductQuestions(
            uid_list_open_product_questions.filter(onlyUnique)
          );
          setUserOrders(uid_list_users_orders);
        })
        .catch((err) => {
          console.error("error in fetching data", err);
          toast.error("Error in fetching data!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
        })


    setLoadingOrders(false);
    toast.success("fetched data successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
    });
  };


    let mounted = true;

    if (mounted) {
     fetchAggregationData();
    }
    return () => {
      mounted = false;
    };
  }, [db]);

  return (
    <Container>
      <Box sx={{ width: 800 }}>
        <Card variant="outlined" sx={{ padding: 3 }}>
          <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
            Push Notifications
          </Typography>

          <form style={{ marginTop: "15px" }} onSubmit={handleClickOpen}>
            {/* Main Required */}
            <div
              style={{
                marginBottom: 15,
                flex: "display",
                justifyContent: "center",
              }}
            >
              <div>
                <Radio
                  checked={selected === "keepoalatesting"}
                  onChange={onValueChange}
                  value="keepoalatesting"
                  name="db"
                />
                <InterStyled interStyle="Button">Keepoala Testing</InterStyled>
              </div>
              <div>
                <Radio
                  checked={selected === "keepoala"}
                  onChange={onValueChange}
                  value="keepoala"
                  name="db"
                />
                <InterStyled interStyle="Button">Keepoala</InterStyled>
              </div>
            </div>

            <FormControlLabel
              sx={{ marginBottom: 3, marginLeft: "0px" }}
              control={
                <Checkbox
                  name="filter_checkbox"
                  onChange={(event) => {
                    setFilteration(event.target.checked);
                  }}
                />
              }
              label="Use Filters"
            />
            {!filteration ? (
              <TextField
                label="UIDs"
                name="uids"
                required
                variant="filled"
                fullWidth
                sx={{ marginBottom: 3 }}
                helperText={uidError}
                error={
                  uidError ===
                  "Comma seperated users ids ex: Edgf45D,EdR45,FER4p"
                    ? false
                    : true
                }
                onChange={(event) => validateUID(event.target.value)}
                multiline
                minRows={3}
              />
            ) : (
              <Box sx={{ marginBottom: 3 }}>
                <FormControl fullWidth>
                  <InputLabel id="filter-label">Filters</InputLabel>
                  <Select
                    labelId="filter-label"
                    id="filter"
                    value={activeFilter}
                    label="Filters"
                    onChange={handleFilterChange}
                    style={{ paddingLeft: 10 }}
                  >
                    <MenuItem
                      style={{
                        display: "block",
                        padding: "5px 10px",
                        fontFamily: "Inter",
                      }}
                      value={"no_voucher"}
                    >
                      users without voucher
                    </MenuItem>
                    <MenuItem value={'orders'}>users with x orders</MenuItem>
                    <MenuItem value={'return_questions'}>
                      open return questions
                    </MenuItem>
                    <MenuItem
                      style={{
                        display: "block",
                        padding: "5px 10px",
                        fontFamily: "Inter",
                      }}
                      value={"product_questions"}
                    >
                      open product questions
                    </MenuItem>
                    <MenuItem value={'keepoalas'}>keepoalas</MenuItem>
                    <MenuItem value={'challenge'}>Users participating in a challenge</MenuItem>
                  </Select>
                </FormControl>
                {activeFilter === 'orders' || activeFilter === 'keepoalas' ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      {/* New code */}
                      <TextField
                        label='From'
                        name='from_number'
                        type='number'
                        InputProps={{ inputProps: { min: 0 } }}
                        required
                        variant='filled'
                        style={{ marginTop: 15 }}
                        size='small'
                        helperText='From Number: value is included'
                        error={
                          rangeError !==
                          'If you want to set a certain number put the same number in both to and from.'
                        }
                      />

                      <span style={{ fontWeight: 900, fontSize: '40px' }}>
                        -
                      </span>

                      <TextField
                        label='To'
                        name='to_number'
                        type='number'
                        InputProps={{ inputProps: { min: 0 } }}
                        required
                        size='small'
                        variant='filled'
                        style={{ marginTop: 15 }}
                        helperText='To Number: value is not included'
                        error={
                          rangeError !==
                          'If you want to set a certain number put the same number in both to and from.'
                        }
                      />
                    </Box>
                    <div
                      style={{
                        textAlign: 'center',
                        color:
                          rangeError ===
                          'If you want to set a certain number put the same number in both to and from.'
                            ? 'rgba(0, 0, 0, 0.6)'
                            : '#d32f2f',
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: ' 0.75rem',
                        lineHeight: '1.66',
                        letterSpacing: '0.03333em',
                      }}
                    >
                      {rangeError}
                    </div>
                  </>
                ) : null}

                {activeFilter === 'challenge' ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        marginTop: 2,
                      }}
                    >
                     <Select
                        labelId='selector-label'
                        id='selector'
                        value={selectedChallenge}
                        onChange={handleChallengeSelection}
                        style={{ width: '80%' }}
                      >
                        {challenges.map((challenge) => {
                        return(
                            <MenuItem value={challenge.id} key={challenge.id}>
                              {challenge.text.en} ({challenge.id})
                              {' '}{challenge.end_date > Date.now() / 1000? 'Ends on': 'Ended on'}{' '}
                              {new Date(challenge.end_date * 1000).toLocaleDateString('de-DE')}
                            </MenuItem>
                            )
                        })}
                      </Select>
                    </Box>
                    {challengeError !== undefined ? 
                      <div
                        style={{
                          textAlign: 'center',
                          color: '#d32f2f',
                          fontFamily: 'Roboto',
                          fontWeight: 400,
                          fontSize: ' 0.75rem',
                          lineHeight: '1.66',
                          letterSpacing: '0.03333em',
                        }}
                      >
                        {challengeError}
                      </div>
                    : null}
                  </>
                ) : null}
              </Box>
            )}
            <TextField
              label="Title"
              name="title"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={titleError === "" ? false : true}
              onChange={(event) => validateTitle(event.target.value)}
              helperText={titleError}
            />
            <TextField
              label="message"
              name="message"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 3 }}
              error={messageError === "" ? false : true}
              helperText={messageError}
              onChange={(event) => validateMessage(event.target.value)}
              multiline
              minRows={4}
            />
            <FormControlLabel
              sx={{ marginBottom: 3, marginLeft: 0 }}
              control={
                <Checkbox
                  name="dynamicLink_checkbox"
                  onChange={(event) => {
                    setDynamicLink(event.target.checked);
                  }}
                />
              }
              label="Add custom dynamic link"
            />
            {dynamicLink ? (
              <Card sx={{ padding: 2 }}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    flex: "display",
                    padding: 10,
                  }}
                >
                  <InterStyled interStyle="Label 1">URL details</InterStyled>
                </div>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <InputLabel id="linktype-label">Link Type</InputLabel>
                  <Select
                    labelId="linktype-label"
                    id="linktype"
                    value={linkType}
                    label="linktype"
                    onChange={handleLinkTypeChange}
                    style={{ paddingLeft: 10 }}
                  >
                    <MenuItem value={'keep'}>Keep</MenuItem>
                    <MenuItem value={'returned'}>Returned</MenuItem>
                    <MenuItem value={'voucherorder'}>Voucher Order</MenuItem>
                    <MenuItem value={'voucher'}>Voucher</MenuItem>
                    <MenuItem value={'challenges'}>Challenges</MenuItem>
                    <MenuItem value={'returnfeedback'}>
                      Returnfeedback Overview
                    </MenuItem>
                    <MenuItem value={'journey'}>Journey</MenuItem>
                  </Select>
                </FormControl>

                {linkType === "voucherorder" || linkType === "voucher" ? (
                  <TextField
                    label="order_document_id"
                    name="order_document_id"
                    variant="filled"
                    required={linkType === "voucherorder"}
                    onChange={(event) => setOrderDocumentID(event.target.value)}
                    fullWidth
                    sx={{ marginBottom: 3 }}
                    helperText={"Add a correct order document ID"}
                  />
                ) : ['challenges', 'returnfeedback', 'journey'].includes(
                    linkType,
                  ) ? null : (
                  <Box>
                    <TextField
                      label="shopify_id"
                      name="shopify_id"
                      variant="filled"
                      required
                      onChange={(event) => setShopifyID(event.target.value)}
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      helperText={"Add a correct shopify ID"}
                    />
                    <TextField
                      label="shopify_name"
                      name="shopify_name"
                      variant="filled"
                      required
                      onChange={(event) => setshopifyName(event.target.value)}
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      helperText={"Add a correct shopify Name"}
                    />
                    <TextField
                      label="shopify_email"
                      name="shopify_email"
                      variant="filled"
                      required
                      onChange={(event) => setshopifyEmail(event.target.value)}
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      helperText={"Add a correct shopify Email"}
                    />
                    <TextField
                      label="shopify_shop"
                      name="shopify_shop"
                      variant="filled"
                      required
                      onChange={(event) => setShopifyShop(event.target.value)}
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      helperText={"Add a correct shopify shop"}
                    />
                    {linkType === "returned" ? (
                      <TextField
                        label="returnedID"
                        name="returnedID"
                        variant="filled"
                        required
                        onChange={(event) => setReturnedID(event.target.value)}
                        fullWidth
                        sx={{ marginBottom: 3 }}
                        helperText={"Add a correct returned ID"}
                      />
                    ) : null}
                  </Box>
                )}
              </Card>
            ) : null}

            <FormControl fullWidth sx={{ marginTop: 3 }}>
              <InputLabel id="icon_label">Icon</InputLabel>
              <Select
                labelId="icon_label"
                id="selecticon"
                value={icon}
                label="Icon"
                onChange={handleIconChange}
                style={{ paddingLeft: 10 }}
              >
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"no"}
                >
                  -
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"information"}
                >
                  information
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"bag"}
                >
                  bag
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"camera"}
                >
                  camera
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"error"}
                >
                  error
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"grinning_face"}
                >
                  grinning_face
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"mailcoming"}
                >
                  mailcoming
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"package"}
                >
                  package
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"thumbsup"}
                >
                  thumbsup
                </MenuItem>
                <MenuItem
                  style={{
                    display: "block",
                    padding: "5px 10px",
                    fontFamily: "Inter",
                  }}
                  value={"trophy"}
                >
                  trophy
                </MenuItem>
              </Select>
            </FormControl>

            <div
              style={{
                textAlign: 'center',
                marginTop: '20px',
                flex: 'display',
                flexDirection: 'column',
              }}
            >
              {/* Create Button */}
              <div style={{ paddingBottom: 10 }}>
                <Button
                  disabled={!formSubmitable || loading_orders}
                  style={{ marginTop: '20px' }}
                  variant='outlined'
                  type='submit'
                >
                  {loading_orders ? (
                    <div
                      style={{
                        marginRight: 60,
                        marginLeft: 60,
                      }}
                    >
                      <CircularProgress size={20} thickness={5} />
                    </div>
                  ) : (
                    'Send Notifications'
                  )}
                </Button>
              </div>
              {loading_orders ? (
                <InterStyled
                  interStyle='Label2'
                  style={{
                    color: Colors.keepoala.greytext,
                    textAlign: 'center',
                  }}
                >
                  Loading..
                </InterStyled>
              ) : null}
            </div>
          </form>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Send Notification with these details?"}
          </DialogTitle>
          {!loading ? (
            <div>
              {' '}
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  <b>Title: </b>
                  {notificationObject.title} <br />
                  <b>message: </b>
                  {notificationObject.message} <br />
                  <b>icon: </b>
                  {icon}~<br />
                  <b>server: </b>
                  {selected}
                  <br />
                  <b>Type: </b>
                  {notificationObject.type}
                  <br />
                  <b>UID Length: </b>
                  {UIDLength}
                  <br />
                  {filteration ? 'filter:' + activeFilter : null}
                  {/* {filteration && activeFilter === 'orders'
                    ? 'Orders ' + operator + ' ' + orderNum
                    : null}
                  {'\n'} */}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </div>
          ) : (
            <div
              style={{
                alignSelf: 'center',
                padding: 20,
              }}
            >
              <CircularProgress size={50} thickness={3} />

              <div>
                <InterStyled
                  interStyle='Label 1'
                  style={{ color: Colors.keepoala.forestGreendefault }}
                >
                  Loading notifications details...
                </InterStyled>
              </div>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </Box>
    </Container>
  );
}

export default PushNotifications;
