import { TableRow, TableCell } from "@mui/material";
import React from "react";
import InterStyled from "../styles/InterStyled";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import {default as firebase, storage} from "../firebase";
import Image from "react-image-enlarger";
import { checkTrackingNumber } from "./CheckTrackingNumber";
import ScanIssueForm from "./ScanIssueForm";

/**
 * @description Component to render a row of scan issues in (table form)
 *
 * @param data the scan issue data
 * @param index the key for each row
 * @param options the auto complete options for pretty shop names
 *
 * @return table row for a scan issue
 *
 * @author youssef
 */

export default function ScanIssuesList({ data, index, options }) {
  const [tracking_number, setTrackingNumber] = React.useState(
    data.tracking_numbers !== undefined ? data.tracking_numbers[0] : ""
  );
  const [can_read, setCanRead] = React.useState(true);
  const [shop_name, setShopName] = React.useState(
    data.shop ?? { label: "", name: "" }
  );
  const [disableSave, setDisableSave] = React.useState(false);

  const [open, setOpen] = React.useState(false);


  const [image_to_show, setImageToShow] = React.useState(data.label_image);

  if (data.label_image.includes("user_uploads")) {
    console.log("receiving label image", data.label_image)
    storage
      .ref(data.label_image)
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'
    
        setImageToShow(url);
      })
      .catch((error) => {
        // Handle any errors
      });
  } else {

  }

  const handleClose = () => {
    setOpen(false);
  };
  const db = firebase.firestore();

  //checking that the tracking number length is valid
  const checkTrackingNumberLength = (value) => {
    return true
    // if (
    //   // special check for DHL JJD measurements
    //   ((value.length === 14 || value.length === 16 || value.length === 21) &&
    //     value.startsWith("JJD")) ||
    //   // special check for new Hermes Labels
    //   (value.length === 20 && value.startsWith("H"))
    // ) {
    //   return true;
    // }
    // return false;
  };

  // validate tracking number
  const validateTrackingNumber = (e) => {
    console.log("valdiating tracking number");
    checkTrackingNumberLength(e.target.value)
      ? setTrackingNumber(e.target.value)
      : setTrackingNumber("");
  };

  // when save button is pressed
  const onSubmit = async (event) => {
    event.preventDefault();

    const input_dict = {
      can_read: can_read,
      tracking_numbers: [tracking_number],
      shop: shop_name.name,
    };
    console.log(input_dict);
    const documentRef = db.collection("ScanIssues").doc(data.doc_id);

    // when can_read is true then add the shop name and tracking numbers
    if (can_read) {
      if (tracking_number === "" || shop_name.name === "") {
        toast.error("Fill all fields...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
        return;
      }
      // Disable the button till finished
      setDisableSave(true);
      toast.info("Updating scanned order...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      documentRef
        .update(input_dict)
        .then((res) => {
          console.log("updated", res);
          toast.success("Successfully updated scanned Order!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setDisableSave(false);
        })
        .catch((err) => {
          console.error(err);
          // Send a notification
          toast.error("Something went wrong...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setDisableSave(false);
        });
    } else {
      // if can_read is false then just update it to false
      documentRef
        .update({
          can_read: false,
        })
        .then((res) => {
          console.log("updated can_read to false", res);
          toast.success("Can read is updated to false", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setDisableSave(false);
        })
        .catch((err) => {
          console.error(err);
          // Send a notification
          toast.error("Something went wrong...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          setDisableSave(false);
        });
    }
  };
  return (
    <TableRow key={index}>
      <TableCell>
        <InterStyled interStyle="Label1" color="greytext">
          {data.doc_id}
        </InterStyled>
      </TableCell>
      <TableCell>
        <Image
          style={{ width: "30px", height: "auto" }}
          src={image_to_show}
          onClick={() => setOpen(true)}
          onRequestClose={() => setOpen(false)}
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              name="can_read_checkbox"
              checked={can_read}
            />
          }
          label=""
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Tracking Number"
          name="tracking_number"
          disabled
          value={tracking_number}
          defaultValue={tracking_number}
          variant="outlined"
          fullWidth
          onChange={validateTrackingNumber}
          minRows={1}
        />
      </TableCell>
      <TableCell>
        <TextField
          disabled
          defaultValue={shop_name.label}
          value={shop_name.label}
          label="Shop"
          name="shop_text_field"
          variant="outlined"
          fullWidth
          minRows={1}
          sx={{ width: 300 }}
        />
      </TableCell>
      <TableCell>
        <div style={{ textAlign: "center", alignSelf: "center" }}>
          {/* Create Button */}
          <Button
            style={{ marginBottom: 16 }}
            variant="outlined"
            type="button"
            onClick={() => {
              setOpen(true);
            }}
          >
            Edit
          </Button>
        </div>
      </TableCell>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ScanIssueForm
          options={options}
          key={index}
          image={image_to_show}
          setShopName={setShopName}
          shop_name={shop_name}
          tracking_number={tracking_number}
          handleClose={handleClose}
          disableSave={disableSave}
          onSubmit={onSubmit}
          validateTrackingNumber={validateTrackingNumber}
          can_read={can_read}
          setCanRead={setCanRead}
          checkTrackingNumberLength={checkTrackingNumberLength}
        />
      </Modal>
    </TableRow>
  );
}
