import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';
import Login from '../../components/Login';
import Home from '../../components/Home';
import ShopOrders from '../../views/Shoporders';
import CreateVoucher from '../../views/CreateVoucher';
import VoucherPartner from '../../views/VoucherPartner';
import Navbar from '../../components/Navbar';
import PushNotifications from '../../views/PushNotification';
import VoucherSend from '../../views/VoucherSend';
import OrderImagesParser from '../../views/OrderImagesParser';
import ScanIssues from '../../views/ScanIssues';
import CreateChallenge from '../../views/CreateChallenge';
import SorryPoints from '../../views/SorryPoints';
import Reporting from '../../views/Reporting';

const Routes = () => {
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) {
    return (
      <div className='center__all'>
        <h1>Loading</h1>
      </div>
    );
  }

  return (
    <>
      {!authenticated ? (
        <Switch>
          <Route exact path='/' render={() => <Login />} />
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        </Switch>
      ) : (
        <>
          <Navbar />
          <div>
            <Switch>
              <Route exact path='/shoporders' render={() => <ShopOrders />} />
              <Route
                exact
                path='/user'
                render={() => (
                  <ShopOrders
                    collection={'User'}
                    allowed={[
                      'email',
                      'key',
                      'registration_date',
                      'registration_datenum',
                    ]}
                    header={[
                      'key',
                      'email',
                      'registration_date',
                      'registration_datenum',
                    ]}
                  />
                )}
              />
              <Route
                exact
                path='/createvoucher'
                render={() => <CreateVoucher />}
              />
              <Route
                exact
                path='/createchallenge'
                render={() => <CreateChallenge />}
              />
              <Route
                exact
                path='/voucherpartner'
                render={() => <VoucherPartner />}
              />
              <Route
                exact
                path='/pushnotifications'
                render={() => <PushNotifications />}
              />
              <Route exact path='/sorrypoints' render={() => <SorryPoints />} />
              <Route exact path='/reporting' render={() => <Reporting />} />
              <Route
                exact
                path='/orderimagesparser'
                render={() => <OrderImagesParser />}
              />
              <Route exact path='/sendvoucher' render={() => <VoucherSend />} />
              <Route exact path='/scanissues' render={() => <ScanIssues />} />
              <Home />
            </Switch>
          </div>
        </>
      )}
    </>
  );
};

export default Routes;
