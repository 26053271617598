import { useState } from "react";
import Box from "@mui/material/Box";
import InterStyled from "../styles/InterStyled";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import MonthlyReporting from "../components/MonthlyReporting";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Reporting() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth={false}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
        <Tab label="Monthly Reporting" />
        <Tab label="PDF Report generation" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <MonthlyReporting />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InterStyled>To be done!</InterStyled>
      </TabPanel>
    </Container>
  );
}
