import React, { useEffect } from 'react';
import {
  TableRow,
  TableCell as TableCellMui,
  Input,
  FormControl,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import styled from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import ZoomImage from './ZoomImage';

const TableCell = styled(TableCellMui)`
    background-color: ${(props) => {
      switch (props.highlight) {
        case 'true':
          return '#90323D';
        default:
          return 'transparent';
      }
    }}};
    color: ${(props) => {
      switch (props.highlight) {
        case 'true':
          return 'white';
        default:
          return 'black';
      }
    }}};
`;
function OrderListRow(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    Date.parse(props.order.date),
  );
  const [keep, setKeep] = React.useState(props.order.keep);
  const [newName, setNewName] = React.useState(props.order.name);
  const [returned, setReturned] = React.useState(props.order.returned);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleKeepStatusChange = () => {
    if (keep) {
      setKeep(false);
    } else {
      setKeep(true);
    }
  };

  const handleReturnedStatusChange = () => {
    if (returned) {
      setReturned(false);
    } else {
      setReturned(true);
    }
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  useEffect(() => {
    setNewName(props.order.name);
    setKeep(props.order.keep);
    setReturned(props.order.returned);
  }, [props.order.name]);

  return (
    <TableRow>
      <TableCell> {props.order.key} </TableCell>
      <TableCell>
        {' '}
        <FormControl margin='normal' fullWidth>
          <Input
            name={props.order.key + 'name'}
            type='text'
            value={newName}
            id={props.order.key + 'name'}
            autoComplete='current-password'
            onChange={handleNameChange}
          />
        </FormControl>{' '}
      </TableCell>
      <TableCell
        highlight={(
          props.order.keep_status_days_remaining /
            props.order.keep_status_days >=
            1 &&
          props.order.keep === false &&
          props.order.returned === false
        ).toString()}
      >
        {props.order.keep_status_days_remaining}/{props.order.keep_status_days}
      </TableCell>
      <TableCell>
        {' '}
        {props.order.user_id !== undefined ? props.order.user_id : ''}{' '}
      </TableCell>
      <TableCell align='left' size='small'>
        <ZoomImage src={props.order.order_image} />
      </TableCell>

      <TableCell align='left' size='small'>
        <ZoomImage src={props.order.label_image} />
      </TableCell>
      <TableCell>
        {props.order.tracking_numbers.map((t_number) => {
          return (
            <div key={t_number}>
              <b>DHL:&nbsp;</b>
              <a
                target='_new'
                href={
                  'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' +
                  t_number
                }
              >
                {t_number}
              </a>
              <br />
              <b>Hermes:&nbsp;</b>
              <a
                target='_new'
                href={
                  'https://www.myhermes.de/empfangen/sendungsverfolgung/sendungsinformation/#' +
                  t_number
                }
              >
                {t_number}
              </a>
            </div>
          );
        })}
      </TableCell>

      {keep ? (
        <TableCell align='left' size='small'>
          {' '}
          True
          <IconButton
            aria-label='true'
            onClick={() => handleKeepStatusChange()}
          >
            <DoneIcon fontSize='small' />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell align='left' size='small'>
          {' '}
          False
          <IconButton
            aria-label='false'
            onClick={() => handleKeepStatusChange()}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        </TableCell>
      )}

      {returned ? (
        <TableCell align='left' size='small'>
          {' '}
          True
          <IconButton
            aria-label='true'
            onClick={() => handleReturnedStatusChange()}
          >
            <DoneIcon fontSize='small' />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell align='left' size='true'>
          {' '}
          False
          <IconButton
            aria-label='false'
            onClick={() => handleReturnedStatusChange()}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        </TableCell>
      )}
      <TableCell align='left' size='small'>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            variant='inline'
            format='MM/dd/yyyy'
            margin='normal'
            id='date-picker-inline'
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </TableCell>

      <TableCell align='left' size='small'>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={() =>
            props.onSave(
              props.order.key,
              keep,
              returned,
              selectedDate,
              newName,
              props.order.user_id,
            )
          }
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default OrderListRow;
