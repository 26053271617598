import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import styled from "styled-components";


const GreenSelectContainer = styled(FormControl)`
  & label.Mui-focused {
    color: #2F8481;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid #2F8481;
  }
`;

export default function MultipleSelectChip(props) {
    // const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        props.onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <GreenSelectContainer variant="filled" sx={{ width: '100%', marginBottom: 3 }}>
                <InputLabel>{props.label}</InputLabel>
                <Select
                    multiple={props.multiple}
                    value={props.value}
                    required={props.required}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: '300px'
                            },
                        },
                    }}
                >
                    {props.data && props.data.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={{ display: "block", padding: '5px 10px', fontFamily: 'Inter' }}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </GreenSelectContainer>
        </div>
    );
}
