import React, { useState, useEffect, useContext } from 'react';
import { OrderList } from '../components/OrderList';
import firebase from '../firebase';
import formatDate from '../utils/helper/formatDate';
import { toast } from 'react-toastify';
import { ConfigContext } from '../utils/providers/ConfigProvider';
import { Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import Item from '../components/Item';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';

function checkDateOnLabelExist(order) {
  if (order.date_on_label !== '') {
    const split_date = order.date_on_label.split('-');
    return new Date(
      parseFloat(split_date[0]),
      parseFloat(split_date[1]) - 1,
      parseFloat(split_date[2]),
      0,
      0,
      0,
      0,
    );
  }
  return new Date(order.datenum * 1000);
}

function getRemainingDate(order) {
  let labelDate = checkDateOnLabelExist(order);

  let msDiff = new Date().getTime() - labelDate.getTime(); //current date - date_on_label
  let daysTill = Math.floor(msDiff / (1000 * 60 * 60 * 24));
  if (daysTill >= order.keep_status_days) {
    return order.keep_status_days !== undefined ? order.keep_status_days : 30;
  }
  return daysTill;
}
export function createReadibleDate(date) {
  const month = date.getUTCMonth() + 1; //months from 1-12
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${year}-${month}-${day}`;
}
export function createReadibleTime(date) {
  const hour = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${hour}:${minutes}`;
}

function OrderListView() {
  const [data, setData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadDataVoucher, setDownloadDataVoucher] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [keepStatus, setKeepStatus] = useState(true);
  const [onlyScans, setOnlyScans] = useState(true);
  const [dateRangeStart, setDateRangeStart] = useState(
    new Date(Date.now() - 120 * 24 * 60 * 60 * 1000),
  );
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date(Date.now()));

  const { config } = useContext(ConfigContext);
  const allowed = [
    'type',
    'order_id',
    'keepoalas',
    'datenum',
    'keep',
    'keepoala_date',
    'keepoala_time',
    'returned',
    'keep_status_days_percent',
    'name',
    'shop',
    'keepoalas_possible',
    'keep_status',
    'return_questions',
    'shopify_date',
    'shopify_time',
    'total_price',
    'total_tax',
    'total_discounts',
    'currency',
    'shopify_name',
    'moved',
    'country',
    'scan',
    'order_scan',
    'user_id',
    'documentID',
  ];

  const db = firebase.firestore();

  const getVoucherData = (event, done) => {
    const converted_start = dateRangeStart.getTime() / 1000;
    const converted_end = dateRangeEnd.getTime() / 1000;

    db.collection('Orders')
      .where('datenum', '>=', converted_start)
      .where('datenum', '<=', converted_end)
      .where('type', 'in', ['voucher', 'bonus'])
      .orderBy('datenum', 'desc')
      .limit(10)
      .get()
      .then(async (querySnapshot) => {
        console.log('WER');

        const orders = await querySnapshot.docs.map((documentSnapshot) => {
          var order_data = documentSnapshot.data();

          return {
            ...order_data,
            documentID: documentSnapshot.id,
            key: documentSnapshot.id,
          };
        });
        setDownloadDataVoucher(
          orders.map((value) => {
            return Object.keys(value)
              .sort()
              .filter((key) => allowed.includes(key))
              .reduce((obj, key) => {
                obj[key] = value[key];
                return obj;
              }, {});
          }),
        );
        done(true);
      })
      .catch((x) => {
        done(true);
      });
  };

  function get_data(start_date, end_date) {
    const converted_start = start_date.getTime() / 1000;
    const converted_end = end_date.getTime() / 1000;

    console.log(converted_start);
    console.log(converted_end);

    db.collection('Orders')
      .where('datenum', '>=', converted_start)
      .where('datenum', '<=', converted_end)
      .where('type', '==', 'order')
      .where('keep', '==', false)
      .where('label_image_bool', '==', onlyScans)
      // .where('user_id','not-in',['dquB74AfJjaBdKLKthnhtWoVPLl1','yGNaYfwSTcU6AiJ2QFolq9EfQnO2','XCGuaMV0lBNUfd6ngUXEi228mLP2'])
      .orderBy('datenum', 'desc')
      .limit(500)
      .get()
      .then(
        async (querySnapshot) => {
          var i = 0;
          console.log('DATA ARRIVED');
          const orders = querySnapshot.docs.map((documentSnapshot) => {
            var order_data = documentSnapshot.data();

            // Overwrite keep status days with config
            order_data.keep_status_days =
              order_data.keep_status_days === undefined
                ? config.goal_keep_days
                : order_data.keep_status_days;
            // overwrite days remnainng
            order_data.keep_status_days_remaining =
              getRemainingDate(order_data);

            order_data.keep_status_days_percent =
              order_data.keep_status_days_remaining /
              order_data.keep_status_days;

            order_data.tracking_number_read =
              order_data.tracking_numbers.length >= 1
                ? order_data.tracking_numbers[0]
                : '';
            if (
              order_data.shopify !== undefined &&
              order_data.shopify.id !== undefined
            ) {
              console.log(order_data.id);
              return db
                .collection('shoporders')
                .where(
                  'shop',
                  '==',
                  order_data.shopify.shop == undefined
                    ? 'any'
                    : order_data.shopify.shop,
                )
                .where(
                  'id',
                  'in',
                  order_data.shopify.id !== undefined
                    ? [order_data.shopify.id, parseFloat(order_data.shopify.id)]
                    : ['anananana'],
                )
                .limit(2)
                .get()
                .then((shop_order_docs) => {
                  if (shop_order_docs.size === 1) {
                    const fitting_shoporder = shop_order_docs.docs[0].data();
                    const input_date = new Date(
                      Date.parse(fitting_shoporder.created_at),
                    );
                    order_data.shopify_date = createReadibleDate(input_date);
                    order_data.shopify_time = createReadibleTime(input_date);
                    order_data.total_price = fitting_shoporder.total_price;
                    order_data.total_tax = fitting_shoporder.total_tax;
                    order_data.total_discounts =
                      fitting_shoporder.total_discounts;
                    order_data.currency = fitting_shoporder.currency;
                    order_data.shopify_name = fitting_shoporder.name;
                    order_data.moved = fitting_shoporder.moved;
                    order_data.country = fitting_shoporder.country;
                  } else {
                    if (
                      order_data.shopify !== undefined &&
                      order_data.shopify.id !== undefined
                    ) {
                      order_data.shopify_id = order_data.shopify.id;
                      order_data.shopify_name = order_data.shopify.name;
                    } else {
                      order_data.shopify_id = undefined;
                      order_data.shopify_name = undefined;
                    }
                    order_data.shopify_date = undefined;
                    order_data.total_price = undefined;
                    order_data.total_tax = undefined;
                    order_data.total_discounts = undefined;
                    order_data.currency = undefined;
                    order_data.moved = undefined;
                    order_data.country = undefined;
                  }
                  const input_keepoala_date = new Date(
                    order_data.datenum * 1000,
                  );

                  order_data.keepoala_date =
                    createReadibleDate(input_keepoala_date);
                  order_data.keepoala_time =
                    createReadibleTime(input_keepoala_date);
                  order_data.keep_status = order_data.keep
                    ? 'keep'
                    : order_data.returned
                    ? order_data.return_questions
                      ? 'returned questions answered'
                      : 'returned'
                    : 'unknown';
                  order_data.scan = order_data.label_image_bool;
                  order_data.order_scan = order_data.order_image_bool;
                  order_data.order_id = order_data.id;

                  return {
                    ...order_data,
                    documentID: documentSnapshot.id,
                    key: documentSnapshot.id,
                  };
                  // i = i + 1;
                })
                .catch((e) => {
                  console.log('FIREBASE ERROR');
                  console.log(e);
                });
            } else {
              return {
                ...order_data,
                documentID: documentSnapshot.id,
                key: documentSnapshot.id,
              };
            }
          });

          Promise.all(orders).then((resolved_orders) => {
            setDownloadData(
              resolved_orders.map((value) => {
                return Object.keys(value)
                  .sort()
                  .filter((key) => allowed.includes(key))
                  .reduce((obj, key) => {
                    console.log(obj.id);
                    obj[key] = value[key];
                    return obj;
                  }, {});
              }),
            );

            setData(
              resolved_orders.filter((resolved_order) => {
                if (onlyScans && keepStatus) {
                  return (
                    resolved_order.type === 'order' &&
                    resolved_order.tracking_numbers.length >= 1 &&
                    resolved_order.keep_status_days_percent >= 1 &&
                    resolved_order.keep === false &&
                    resolved_order.returned === false &&
                    resolved_order.name.match('kein Retourenlabel') === null
                  );
                } else if (onlyScans) {
                  return (
                    resolved_order.type === 'order' &&
                    resolved_order.tracking_numbers.length >= 1 &&
                    // true
                    resolved_order.keep === false &&
                    resolved_order.returned === false
                  );
                } else if (keepStatus) {
                  return (
                    resolved_order.type === 'order' &&
                    resolved_order.tracking_numbers.length < 1 &&
                    resolved_order.keep_status_days_percent >= 1 &&
                    // true
                    resolved_order.keep === false &&
                    resolved_order.returned === false
                  );
                } else {
                  return (
                    resolved_order.type === 'order' &&
                    resolved_order.tracking_numbers.length < 1
                  );
                }
              }),
            );
          });

          setIsLoading(false);
          toast.info('new data loaded', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        },
        (err) => console.log('ERROR', err),
      )
      .catch((err) => {
        console.log('err', err);
        console.log(err.code);
      });
  }

  useEffect(() => {
    console.log('Here');
    if (isLoading && config !== undefined) {
      console.log('THere');
      setData([]);
      get_data(dateRangeStart, dateRangeEnd);
    }
  }, [config]);

  const saveChanges = (orderID, keep, returned, selectedDate, newName, uid) => {
    const date_str = formatDate(selectedDate);
    const input_dict = {
      id: orderID,
      user_id: uid,
      keep: keep,
      name: newName !== undefined ? newName : '',
      returned: returned,
      date_on_label:
        date_str !== 'NaN-NaN-NaN' && date_str !== '1970-1-1' ? date_str : '',
    };

    db.collection('OrderChange').add({
      type: 'change',
      dict: input_dict,
    });

    toast.info('Data send to DB, reload in 4s', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // setTimeout(() => {
    //   setIsLoading(true)
    //   get_data()
    // }, 4000);
  };

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <Item>
          {' '}
          <CSVLink data={downloadData}>
            Download all Order and Voucher data
          </CSVLink>
        </Item>
        <Item>
          {' '}
          <CSVLink
            data={downloadDataVoucher}
            asyncOnClick={true}
            onClick={getVoucherData}
          >
            Voucher & Bonus data
          </CSVLink>
        </Item>
        <Item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label='From'
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              id='date-picker-inline'
              value={dateRangeStart}
              onChange={(value) => {
                setDateRangeStart(value);
              }}
              maxDate={Date.now()}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              label='To'
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              id='date-picker-inline'
              value={dateRangeEnd}
              onChange={(value) => {
                setDateRangeEnd(value);
              }}
              maxDate={Date.now()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Item>
        <Item>
          <FormControlLabel
            control={
              <Checkbox
                checked={keepStatus}
                onChange={(event) => {
                  setKeepStatus(event.target.checked);
                }}
                name='keepstatus'
                color='primary'
              />
            }
            label='Keep Status > 1'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyScans}
                onChange={(event) => {
                  setOnlyScans(event.target.checked);
                }}
                name='keepstatus'
                color='primary'
              />
            }
            label='Scans ?'
          />
          <Button
            onClick={() => {
              get_data(dateRangeStart, dateRangeEnd);
            }}
          >
            Reload all data
          </Button>
        </Item>
      </Box>

      <OrderList
        data={data}
        onSave={(orderID, keep, returned, selectedDate, newName, uid) =>
          saveChanges(orderID, keep, returned, selectedDate, newName, uid)
        }
      ></OrderList>
    </div>
  );
}

export default OrderListView;
