import React, { useState, useEffect } from 'react';
import styled_component from 'styled-components';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import firebase from '../firebase';
// import { toast } from 'react-toastify';
import Button from '../styles/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ZoomImage from 'react-image-enlarger';

const Container = styled_component.div`
  min-height: calc(100vh - 64px - 80px);
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
`;

const GreenTextField = styled_component(TextField)`
  & label.Mui-focused {
    color: #2f8481;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid #2f8481;
  }
`;

const TableCell = styled(MUITableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  maxWidth: '20vw',
}));

const TableRow = styled(MUITableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

/**
 * Line Items Data table that shows line items for a single order
 * @param {*} props
 * @returns
 */
function LineItemsDataTable(props) {
  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>id</TableCell>
            <TableCell>title</TableCell>
            <TableCell>price</TableCell>
            <TableCell>currency</TableCell>
            <TableCell>quantity</TableCell>
            <TableCell>variant_title</TableCell>
            <TableCell>variant_id</TableCell>
            <TableCell style={{ textAlign: 'center' }}>Controls</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.SKU}</TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.price}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>{row.quantity}</TableCell>
              <TableCell>{row.variant_title}</TableCell>
              <TableCell>{row.variant_id}</TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <IconButton
                  sx={{ color: 'red !important' }}
                  onClick={() => props.removeItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

/**
 * Row Table component that renders an Order that has an order_image
 * @param {*} props
 * @returns
 */
function OrderImageTableRow(props) {
  const { row, number, handleOpenEditDialog } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* Number # */}
        <TableCell>{number}</TableCell>
        {/* Order ID */}
        <TableCell>{row.id}</TableCell>
        {/* Order Image */}
        <TableCell>
          <img
            src={row.order_image}
            alt='There seems to be an error with this one'
            height={200}
            width={200}
          />
        </TableCell>
        {/* Order Image OCR */}
        <TableCell>{row.order_image_ocr}</TableCell>
        {/* Order Name */}
        <TableCell>{row.shopify?.name}</TableCell>
        {/* Edit Button */}
        <TableCell>
          <Button
            onClick={() => {
              handleOpenEditDialog(number - 1);
            }}
          >
            Edit
          </Button>
        </TableCell>
        {/* Scraped */}
        <TableCell>{row.scraped ? 'True' : 'False'}</TableCell>
        {/* Collapse Button */}
        <TableCell>
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1, textAlign: 'center' }}>
              <Typography variant='h6' gutterBottom component='div'>
                Shopify Line Items
              </Typography>
              {/* Line Items Table */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function OrderImagesParser() {
  /**
   * State Variables
   */

  const db = firebase.firestore();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scrapedFilter, setScrapedFilter] = useState('BOTH');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentEditedOrder, setCurrentEditedOrder] = useState({
    index: null,
    id: null,
    image: null,
    ocr: null,
    name: null,
    line_items: null,
    document_id: null,
  });

  // Get data on mount
  useEffect(() => {
    db.collection('Orders')
      .where('order_image', '!=', '')
      .get()
      .then((querySnapshot) => {
        const tempOrders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (
            data.shopify !== undefined &&
            data.shopify.line_items !== undefined &&
            data.shopify.line_items.length > 0
          ) {
            tempOrders.push({ ...data, scraped: true, document_id: doc.id });
          } else {
            tempOrders.push({ ...data, scraped: false, document_id: doc.id });
          }
        });
        setOrders(tempOrders);
        setFilteredOrders(tempOrders);
        setLoading(false);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  }, [db]);

  /**
   * Functions
   */

  const handleScrapeFilterChange = (event, newValue) => {
    let tempFilteredOrders = [];
    if (newValue === 'BOTH') {
      tempFilteredOrders = [...orders];
    } else if (newValue === 'FALSE') {
      orders.forEach((order) => {
        if (order.scraped === false) tempFilteredOrders.push(order);
      });
    } else if (newValue === 'TRUE') {
      orders.forEach((order) => {
        if (order.scraped === true) tempFilteredOrders.push(order);
      });
    }
    setFilteredOrders(tempFilteredOrders);
    setScrapedFilter(newValue);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentEditedOrder({
      index: null,
      id: null,
      image: null,
      ocr: null,
      name: '',
      line_items: [],
      document_id: null,
    });
  };

  const handleOpenEditDialog = (orderIndex) => {
    setCurrentEditedOrder({
      index: orderIndex,
      id: filteredOrders[orderIndex].id,
      image: filteredOrders[orderIndex].order_image,
      ocr: filteredOrders[orderIndex].order_image_ocr,
      name: filteredOrders[orderIndex]?.shopify?.name ?? '',
      line_items: filteredOrders[orderIndex]?.shopify?.line_items ?? [],
      document_id: filteredOrders[orderIndex].document_id,
    });
    setOpenEditDialog(true);
  };

  const handleAddLineItem = (event) => {
    event.preventDefault();

    if (!event.target.reportValidity()) return;

    const formData = {
      SKU: event.target.SKU.value,
      price: event.target.price.value,
      currency: event.target.currency.value,
      quantity: event.target.quantity.value,
      title: event.target.title.value,
      id: event.target.id.value,
      variant_title: event.target.variant_title.value,
      variant_id: event.target.variant_id.value,
    };

    // Add the line item to the others
    const tempLineItems = [...currentEditedOrder.line_items];
    tempLineItems.push(formData);
    setCurrentEditedOrder({ ...currentEditedOrder, line_items: tempLineItems });
  };

  const handleRemoveItemCurrentEditedOrder = (index) => {
    const tempLineItems = [...currentEditedOrder.line_items];
    tempLineItems.splice(index, 1);
    setCurrentEditedOrder({
      ...currentEditedOrder,
      line_items: tempLineItems,
    });
  };

  const saveParsedLineItemsAndName = () => {
    console.log(currentEditedOrder);
    // Disable the save button and show loading

    // Send the change request

    // Change the object if success
    const tempFilteredOrders = [...filteredOrders];
    const tempEditedOrder = {
      ...tempFilteredOrders[currentEditedOrder.index],
    };
    if (
      tempEditedOrder.shopify !== undefined &&
      typeof tempEditedOrder.shopify === 'object' &&
      !Array.isArray(tempEditedOrder.shopify) &&
      tempEditedOrder.shopify !== null
    ) {
      tempEditedOrder.shopify.line_items = currentEditedOrder.line_items;
      tempEditedOrder.shopify.name = currentEditedOrder.name;
    } else {
      tempEditedOrder.shopify = {
        line_items: currentEditedOrder.line_items,
        name: currentEditedOrder.name,
      };
    }
    tempFilteredOrders[currentEditedOrder.index] = tempEditedOrder;
    setFilteredOrders(tempFilteredOrders);

    // Show error if fail

    // Re enable the save button and close the screen
  };

  /**
   * Helper Componenets
   */

  const ScrapedSelect = () => {
    return (
      <RadioGroup
        value={scrapedFilter}
        onChange={handleScrapeFilterChange}
        row
        style={{ fontSize: '10px !important' }}
      >
        <FormControlLabel
          value='TRUE'
          control={
            <Radio
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 10,
                },
              }}
            />
          }
          label={<div style={{ fontSize: '10px' }}>True</div>}
        />
        <FormControlLabel
          value='FALSE'
          control={
            <Radio
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 10,
                },
              }}
            />
          }
          label={<div style={{ fontSize: '10px' }}>False</div>}
        />
        <FormControlLabel
          value='BOTH'
          control={
            <Radio
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 10,
                },
              }}
            />
          }
          label={<div style={{ fontSize: '10px' }}>Both</div>}
        />
      </RadioGroup>
    );
  };

  const ZoomImageComponent = ({ src }) => {
    const [zoomed, setZoomed] = React.useState(false);

    return (
      <div style={{ margin: '0.25rem' }}>
        <ZoomImage
          style={{ width: '100%', height: 'auto' }}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    );
  };

  return (
    <Container>
      <Box sx={{ width: '100vw', margin: 0 }}>
        {/* Loading */}
        {loading && (
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 64px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size={100} thickness={3} />
          </div>
        )}
        {/* Data Table */}
        {!loading && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 'calc(100vh - 64px)' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Order Image</TableCell>
                  <TableCell>Order Image OCR</TableCell>
                  <TableCell>Order Name</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>
                    Scraped
                    <ScrapedSelect />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOrders.map((order, index) => (
                  <OrderImageTableRow
                    key={index}
                    number={index + 1}
                    row={order}
                    handleOpenEditDialog={handleOpenEditDialog}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {/* Edit Dialog */}
      <Dialog
        fullWidth={true}
        maxWidth='xl'
        open={openEditDialog}
        onClose={handleCloseEditDialog}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          Order ID: {currentEditedOrder.id}
        </DialogTitle>
        <DialogContent>
          {/* Details of the Selected Order for Editing */}
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Grid container>
                {/* Order Name */}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography
                    variant='subtitle2'
                    style={{ textAlign: 'center', fontWeight: 700 }}
                    gutterBottom
                    component='div'
                  >
                    Order Name
                  </Typography>
                  <GreenTextField
                    hiddenLabel
                    variant='filled'
                    size='small'
                    margin='dense'
                    value={currentEditedOrder.name}
                    onChange={(event) => {
                      setCurrentEditedOrder({
                        ...currentEditedOrder,
                        name: event.target.value,
                      });
                    }}
                  />
                </Grid>
                {/* Order Image */}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography
                    variant='subtitle2'
                    style={{ textAlign: 'center', fontWeight: 700 }}
                    gutterBottom
                    component='div'
                  >
                    Order Image
                  </Typography>
                  <ZoomImageComponent src={currentEditedOrder.image} />
                </Grid>
                {/* Order Image OCR */}
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle2'
                    style={{ textAlign: 'center', fontWeight: 700 }}
                    gutterBottom
                    component='div'
                  >
                    Order Image OCR
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    style={{ textAlign: 'center' }}
                    gutterBottom
                    component='div'
                  >
                    {currentEditedOrder.ocr}
                  </Typography>
                </Grid>
                {/* Line Items Table */}
                <Grid item xs={12}>
                  <Typography
                    variant='subtitle2'
                    style={{ textAlign: 'center', fontWeight: 700 }}
                    gutterBottom
                    component='div'
                  >
                    Orders Line Items
                  </Typography>
                  <LineItemsDataTable
                    rows={currentEditedOrder.line_items}
                    removeItem={handleRemoveItemCurrentEditedOrder}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* New Line item Form */}
            <Grid item xs={4}>
              <form
                onSubmit={handleAddLineItem}
                style={{ position: 'sticky', top: '0px' }}
              >
                <GreenTextField
                  label='SKU'
                  helperText='item number or whatever the shop uses'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='SKU'
                />
                <GreenTextField
                  label='Price'
                  type='number'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='price'
                  inputProps={{
                    step: 'any',
                  }}
                />
                <GreenTextField
                  label='Curreny'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='currency'
                  select
                  defaultValue='EUR'
                >
                  <MenuItem value='EUR' style={{ display: 'block' }}>
                    EUR
                  </MenuItem>
                  <MenuItem value='USD' style={{ display: 'block' }}>
                    USD
                  </MenuItem>
                </GreenTextField>
                <GreenTextField
                  label='Quantity'
                  type='number'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='quantity'
                />
                <GreenTextField
                  label='Title'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='title'
                />
                <GreenTextField
                  label='ID'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='id'
                />
                <GreenTextField
                  label='Variant Title'
                  helperText='XL, L, M ...'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='variant_title'
                />
                <GreenTextField
                  label='Variant ID'
                  required
                  variant='filled'
                  size='small'
                  margin='dense'
                  fullWidth
                  name='variant_id'
                />
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Button size='small' variant='outlined' type='submit'>
                    Add Line Item
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={saveParsedLineItemsAndName}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default OrderImagesParser;
