import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';

export const ConfigContext = React.createContext(undefined);

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState();

  const db = firebase.firestore();
  // try {db.settings({
  //     host: "localhost:8090",
  //     ssl: false
  // });} catch {
  //     console.log("Cannot init")
  // }
  useEffect(() => {
    if (config === undefined) {
      console.log('here');
      db.collection('KeepoalaConfig')
        .doc('config')
        .get()
        .then((documentSnapshot) => setConfig(documentSnapshot.data()));
    }
  }, [db, config]);

  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
