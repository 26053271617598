import React, { useState } from "react";
import styled from "styled-components";
import InterStyled from "../styles/InterStyled";
import BaseButton from "../styles/Button";
import SmallKeepoalagrey from "../assets/img/smallKeepoalagrey.svg";
import Colors from "../utils/helper/Colors";
import IconButton from "@mui/material/IconButton";
import MUIButton from '@mui/material/Button';
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const Container = styled.div`
  background-color: #ffffff;
  width: 375px;
  margin-top: 20px;
  align-content: center;
  flex-direction: column;
  margin-bottom:30px;
`;
const ImagePreview = styled.div`
  height: 663px;
  max-width: 375px;
`;
const RedeemBox = styled.div`
  max-width: 375px;
  flex-direction: column;
  padding: 12px 16px;
`;
const DetailBox = styled.div`
  max-width: 361px;
  flex-direction: column;
  padding: 12px 20px;
  display: flex;
`;

const Button = styled(BaseButton)`
  font-size: 12px !important;
  margin-top: 15px !important;
  min-height: 33px !important;
`;

export default function Preview(props) {
  const [descriptionLanguage, setDescriptionLanguage] = useState("en");

  const tagBox = (tagName) => {
    return (
      <div
        style={{
          backgroundColor: Colors.keepoala.greylight,
          height: 32,
          borderRadius: 8,
          boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.15)",
          display: "flex",
          alignItems: "center",
          marginRight: 9,
          paddingTop: 4,
          paddingRight: 14,
          paddingBottom: 4,
          paddingLeft: 14,
        }}
      >
        <InterStyled
          interStyle="P1"
          style={{
            color: Colors.keepoala.greytext,
            fontWeight: 500,
          }}
        >
          {tagName}
        </InterStyled>
      </div>
    );
  };

  if (props.previewData) {
    return (
      <Container>
        <ImagePreview>
          <img
            src={props.previewData.feature_images[0].src}
            height={663}
            width={375}
            style={{ position: "absolute" }}
            alt="main"
          />
          <div
            style={{
              position: "relative",
              top: 610,
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InterStyled
              interStyle="H2"
              style={{
                fontSize: 36,
                fontWeight: 700,
                color: "#FFFFFF",

                paddingLeft: 16,
              }}
            >
              {props.previewData.partnername}
            </InterStyled>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <IconButton
                onClick={() => console.log("to next picture")}
              >
                <CircleIcon fontSize="small" style={{ color: "#FFFFFF" }} />
                <CircleOutlinedIcon
                  fontSize="small"
                  style={{ color: "#FFFFFF" }}
                />
                <CircleOutlinedIcon
                  fontSize="small"
                  style={{ color: "#FFFFFF" }}
                />
              </IconButton>
            </div>
          </div>
        </ImagePreview>
        <RedeemBox>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={SmallKeepoalagrey} height="15" width="20" alt="keepoalas" />
              <InterStyled interStyle="H3">2100 </InterStyled>
            </div>
            <InterStyled interStyle="H3">5 EUR - für Fernbusreisen</InterStyled>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <InterStyled interStyle="H3">useless Text</InterStyled>
          </div>

          <Button color="primary" fullWidth>
            redeem voucher
          </Button>
        </RedeemBox>
        <DetailBox>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src={props.previewData.logo[0].src} width="178" height="32" alt="partner logo" />
            <MUIButton onClick={() => {setDescriptionLanguage(descriptionLanguage === 'en' ? 'de' : 'en')}}> {descriptionLanguage === 'en' ? 'DE' : 'EN'} </MUIButton>
          </div>
          {descriptionLanguage === 'en' ? <InterStyled
            interStyle="P1"
            color="greytext"
            style={{ paddingTop: 15, fontWeight: 500 }}
          >
            {props.previewData.partnerdescriptionen}
          </InterStyled> :
            <InterStyled
              interStyle="P1"
              color="greytext"
              style={{ paddingTop: 15, fontWeight: 500 }}
            >
              {props.previewData.partnerdescriptionde}
            </InterStyled>
          }
          <InterStyled
            interStyle="P1"
            style={{
              paddingTop: 15,
              color: Colors.keepoala.greytext,
              fontWeight: 600,
            }}
          >
            Brands at {props.previewData.partnername}
          </InterStyled>
          <div style={{ flexDirection: "row", paddingTop: 15, display: "flex" }}>
            {props.previewData.brands.map((tag, index) => (
              <div key={index}>{tagBox(tag)}</div>
            ))}
          </div>
          <InterStyled
            interStyle="P1"
            style={{
              color: Colors.keepoala.greytext,
              fontWeight: 600,
              paddingTop: 15,
            }}
          >
            Tags
          </InterStyled>
          <div style={{ flexDirection: "row", paddingTop: 15, display: "flex" }}>
            {props.previewData.productstags.map((tag, index) => (
              <div key={index}>{tagBox(tag)}</div>
            ))}
          </div>
          <InterStyled
            interStyle="P1"
            style={{
              color: Colors.keepoala.greytext,
              fontWeight: 600,
              paddingTop: 15,
            }}
          >
            Voucher Terms and conditions
          </InterStyled>
          <InterStyled
            interStyle="P1"
            style={{
              paddingTop: 15,
              color: Colors.keepoala.greytext,
              fontWeight: 500,
              textAlign: 'justify'
            }}
          >
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </InterStyled>
          <Button color="primary" fullWidth>
            redeem voucher
          </Button>
        </DetailBox>
      </Container >
    );
  } else {
    return <div></div>;
  }
}
