import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './utils/router/Router';
import './assets/fonts/index.css';
import Colors from './utils/helper/Colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.keepoala.main,
      },
      secondary: {
        main: Colors.keepoala.dark,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
