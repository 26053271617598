import React, { useContext, useState, useEffect } from 'react';
import OrderListView from '../views/OrderListView';
import 'react-toastify/dist/ReactToastify.css';
import OrderUpdate from './OrderUpdate';
import firebase from '../firebase';

function Home() {
  const [emails, setEmails] = useState([]);
  const [parentDoc, setParentDoc] = useState([]);

  const db = firebase.firestore();

  useEffect(() => {
    // db.collectionGroup("emails").where("email", "==", "zappingseb@googlemail.com").get().then((docs) => {
    //     if (docs.size > 0) {
    //         setEmails([docs.docs[0].data().email])
    //         setParentDoc(docs.docs[0].ref.parent.parent.id)
    //     } else {
    //         console.log(docs.size)
    //     }
    // })
  }, []);

  const resetVouchers = () => {
    const order_ids = [{}];

    order_ids.forEach(async (element) => {
      const input_dict = {
        id: element.id,
        user_id: element.uid,
        keep: true,
      };
      await db
        .collection('OrderChange')
        .add({
          type: 'change',
          dict: input_dict,
        })
        .then(() =>
          console.log('Order Keep' + element.id + ' with user ' + element.uid),
        );
    });

    // user_ids.forEach(async (element) => {

    //     await db.collection("VoucherOrder").add({
    //         user_id: element,
    //         dict: {
    //           heading2: 'welcome',
    //           heading1: 'Welcome',
    //           keepoalas: 20,
    //           partnerid: "Keepoala",
    //         },
    //       }).then(() => console.log("Welcome Bonus set up for UID" + element))
    // });

    // const emails = [
    //     "jajahannah@hotmail.com",
    //     "nadine.schwerdtfeger84@gmx.de",
    //     "kgrundhoeffer@gmx.de",
    //     "gaertnerjonas@gmx.de",
    //     "tina.zechmeister@gmail.com",
    //     "estiletti.b@gmail.com",
    //     "annasybilleott@gmail.com",
    //     "juliafautz@web.de",
    //     "eva.poell@freenet.de",
    //     "dahlmann.anja@web.de",
    //     "verena.stangelmaier@gmail.com",
    //     "estiletti.b@gmail.com",
    //     "moritz.roever@live.de",
    //     "theresajanson@gmx.de",
    //     "miriamkrieger@hotmail.com",
    //     "elena.sytko@hotmail.de",
    //     "verena.stangelmmaier@gmail.com",
    //     "kristin.koesters@web.de",
    //     "karlaengbarth@t-online.de"
    // ]
    // emails.forEach(async (element) => {

    //     await db.collection("_MailChimpListener_").add({
    //         emailAddress: element.trim(),
    //         activity: ["ErlichManuelEvent"]
    //       }).then(() => console.log("Erlich set up for email" + element))
    // });
  };

  return (
    <div>
      <OrderListView />
    </div>
  );
}

export default Home;
