import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from "./utils/providers/AuthProvider"
import ConfigProvider from "./utils/providers/ConfigProvider"

ReactDOM.render(
  <AuthProvider>
    <ConfigProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ConfigProvider>
  </AuthProvider>,
  document.getElementById('root')
);
