import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  FormControl,
  Input,
  InputLabel,
  Button as MuiButton,
  Paper,
  Typography,
  Link,
} from '@mui/material';
import { spacing } from '@mui/system';
import { Alert as MuiAlert } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../utils/providers/AuthProvider';

const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { handleSignIn } = useContext(AuthContext);
  console.log("NEW DEPLOY");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await handleSignIn(email, password);
    if (response.status === false) {
      setShowAlert(true);
      setAlertMessage(response.data);
    } else {
      setShowAlert(false);
      setAlertMessage('');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '50%',
        }}
      >
        <Avatar
          variant='square'
          alt='Login logo'
          src='./assets/img/Keepoala_business_card.png'
        />

        <Typography component='h2' variant='body1' align='center'>
          Admin Panel for Order Management
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl margin='normal' required fullWidth>
            <InputLabel htmlFor='email'>Email Address</InputLabel>
            <Input
              id='email'
              name='email'
              value={email}
              autoComplete='email'
              autoFocus
              onChange={handleEmailChange}
            />
          </FormControl>
          <FormControl margin='normal' required fullWidth>
            <InputLabel htmlFor='password'>Password</InputLabel>
            <Input
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              id='password'
              autoComplete='current-password'
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility
                        style={{ width: 16, height: 16, margin: -8 }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ width: 16, height: 16, margin: -8 }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            mb={2}
            id='submit'
            type='submit'
            value='Submit'
            style={{ marginTop: 20 }}
          >
            Sign in
          </Button>
          <Typography
            variant='body1'
            component='h2'
            align='center'
            style={{ marginTop: 10 }}
          >
            Forgot password?{' '}
            <Link href='' style={{ textDecoration: 'none' }}>
              Contact Us
            </Link>
          </Typography>
        </form>
        {showAlert && (
          <Alert
            mb={4}
            severity='error'
            style={{ marginTop: 15, width: '90%' }}
          >
            {alertMessage}
          </Alert>
        )}
      </div>

      <div style={{ display: 'flex', marginTop: 'auto' }}>
        <Typography
          style={{ margin: 5, textDecoration: 'none' }}
          variant='body1'
          align='center'
          color='primary'
        >
          <Link href='' style={{ textDecoration: 'none' }}>
            Terms of Use
          </Link>
        </Typography>
        <Typography
          style={{ margin: 5, textDecoration: 'none' }}
          variant='body1'
          align='center'
          color='primary'
        >
          <Link href='' style={{ textDecoration: 'none' }}>
            Privacy Policy
          </Link>
        </Typography>
      </div>
    </div>
  );
}
export default Login;
