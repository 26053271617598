import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';
import OrderListRow from './OrderListRow';

export const dataTableStyle = {
  marginBottom: '36px',
};

export const headerStyle = {
  fontWeight: 'bold',
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const OrderList = ({ data, onSave }) => {
  const [order, setOrder] = React.useState('keep_status_days');
  const [orderBy, setOrderBy] = React.useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer plain='true' style={dataTableStyle}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            {[
              {
                label: 'Order ID',
                field: 'id',
              },
              {
                label: 'Order Name',
                field: 'name',
              },
              {
                label: 'Days to keep',
                field: 'keep_status_days_percent',
              },
              {
                label: 'User ID',
                field: 'user_id',
              },
              {
                label: 'Order Image',
                field: '',
              },
              {
                label: 'Label Image',
                field: '',
              },
              {
                label: 'Tracking Links',
                field: '',
              },
              {
                label: 'Keep status',
                field: 'keep',
              },
              {
                label: 'Returned status',
                field: 'returned',
              },
              {
                label: 'Date',
                field: 'date',
              },
              {
                label: 'Actions',
                field: '',
              },
            ].map((x) => (
              <TableCell
                style={headerStyle}
                key={x.label.replace(/\s/g, '.')}
                align={'left'}
              >
                {x.label}
                {x.field !== '' ? (
                  <TableSortLabel
                    active={orderBy === x.field}
                    direction={orderBy === x.field ? order : 'asc'}
                    onClick={createSortHandler(x.field)}
                  >
                    {orderBy === x.field ? (
                      <span
                        style={{
                          border: 0,
                          clip: 'rect(0 0 0 0)',
                          height: 1,
                          margin: -1,
                          overflow: 'hidden',
                          padding: 0,
                          position: 'absolute',
                          top: 20,
                          width: 1,
                        }}
                      >
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy)).map((order, i) => (
            <OrderListRow
              key={i}
              order={order}
              onSave={(orderID, keep, returned, selectedDate, newName, uid) =>
                onSave(orderID, keep, returned, selectedDate, newName, uid)
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
