import React from "react";
import styled from "styled-components";
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Kamera from '../assets/img/Kamera.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const ImageUploaderContainer = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

const ImagesContainer = styled.div`
    width: 100%;
`;

const ErrorMessage = styled.div`
    width: 100%;
    text-align:center;
    color:rgb(211, 47, 47);
`;

const UploadButton = styled(Button)`
    height: 150px;
    width: 100%;
    margin: 15px 10px 15px 0px !important;
    border: 1px dashed black !important;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;

    & div.text {
        font-family:Inter;
        color:black;
        text-transform: none;
    }
`;


function ImageUploader(props) {

    // Helper Functions
    const addImageInfo = (event, index, imagesToAdd) => {

        if (event.target.files.length < index + 1) {
            props.setImages([...props.images, ...imagesToAdd]);
            return;
        };

        // Upload files
        const file = event.target.files[index];

        if (file) {
            const type = file.type;
            const reader = new FileReader();

            reader.onloadend = function () {
                const img = new Image();
                img.src = reader.result; // Base64-encoded image
                img.onload = function () {
                    imagesToAdd.push({
                        src: reader.result,  // Use Base64 instead of objectUrl
                        name: file.name,
                        type,
                        file,
                        size: (file.size / (1024 * 1024))  // Convert size to MB
                    });
                    addImageInfo(event, index + 1, imagesToAdd);
                };
            };

            reader.readAsDataURL(file);  // Convert file to Base64 data URL
        }

    };


    const removeImageInfo = (imageName) => {
        const newImages = [];
        let deleted = false;
        props.images.forEach((image) => {
            if (!deleted && image.name === imageName) {
                deleted = true;
            } else {
                newImages.push(image);
            }
        });

        props.setImages([...newImages]);
    };

    return (
        <ImageUploaderContainer>
            <div style={{ width: '100%' }}>
                <InputLabel sx={{ color: props.errorMessage ? 'rgb(211, 47, 47)' : 'initial' }}>{props.label}</InputLabel>

                <UploadButton fullWidth variant="outlined" component="label">
                    <img src={Kamera} alt='camera' />
                    <div className='text'>Drop files here or <span style={{ fontWeight: '700' }}>Browse</span></div>
                    <input label="Photo" name="photo" hidden accept="image/*" type="file"
                        multiple
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={(event) => addImageInfo(event, 0, [])}
                    />
                </UploadButton>

                {props.errorMessage && <ErrorMessage>
                    {props.errorMessage}
                </ErrorMessage>
                }

                <ImagesContainer>

                    {props.images.map((image, index) =>
                        <Card key={image.name + index} sx={{ marginBottom: '10px' }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '4px !important' }}>
                                <div style={{ fontFamily: 'Inter', width: '70%' }}>{image.name}</div>
                                <img width="60" height="60" src={image.src} alt={image.name} />
                                <IconButton sx={{ width: '10%' }} onClick={() => removeImageInfo(image.name)}>
                                    <ClearIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    )}

                </ImagesContainer>
            </div>

        </ImageUploaderContainer>
    );
}

export default ImageUploader;