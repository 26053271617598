import React from 'react';
import InterStyled from '../styles/InterStyled';
import styled from 'styled-components';

import Button from '@mui/material/Button';

import { toast } from 'react-toastify';

import firebase from '../firebase';

import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';

import { dataTableStyle } from '../components/OrderList';
import ScanIssuesList from '../components/ScanIssuesList';
import ScanIssueForm from '../components/ScanIssueForm';
/**
 * @description ScanIssue View for displaying the scans that cannot be read
 *              for updating tracking numbers and shop names
 *
 * @author youssef
 */

const Container = styled.div`
  padding: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export default function ScanIssues() {
  const db = firebase.firestore();
  const [scan_issues, setScanIssues] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [shop_options, setShopOptions] = React.useState([{ label: '' }]);
  const [load_all, setLoadAll] = React.useState(false);

  // Getting pretty shop names for autocompletion
  const fetchShopNames = () => {
    db.collection('Shops')
      .get()
      .then((querySnapshot) => {
        let pretty_shop_name = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().pretty !== '' && doc.data().pretty !== undefined) {
            pretty_shop_name.push({
              label: doc.data().pretty,
              name: doc.data().name,
            });
          } else {
            if (doc.data().name !== undefined && doc.data().name !== '') {
              pretty_shop_name.push({
                label: doc.data().name,
                name: doc.data().name,
              });
            }
          }
        });
        //remove duplicates
        const filter_pretty = [
          ...new Map(pretty_shop_name.map((v) => [v.label, v])).values(),
        ];

        setShopOptions(filter_pretty);
        console.log('fetched shop names');
        console.log(filter_pretty);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };

  // getting the scan issues that don't have can_read or tracking numbers
  const fetchScanIssues = (limit) => {
    let limit_number = 30;
    if (limit) {
      setLoadAll(true);
      limit_number = 1000;
      setIsLoading(true);
    }
    db.collection('ScanIssues')
      .limit(limit_number)
      .orderBy('datetime')
      .get()
      .then((querySnapshot) => {
        let not_read_issues = [];
        querySnapshot.forEach((doc) => {
          if (
            (doc.data().can_read === undefined &&
              doc.data().tracking_numbers === undefined) ||
            (doc.data().can_read !== false &&
              doc.data().tracking_numbers === undefined)
          ) {
            not_read_issues.push({
              can_read: doc.data().can_read,
              label_image: doc.data().label_image,
              doc_id: doc.id,
              tracking_numbers: doc.data().tracking_numbers,
              shop: doc.data().shop,
            });
          }
        });
        console.log(not_read_issues);
        setScanIssues(not_read_issues);
        setIsLoading(false);

        toast.info('new data loaded', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        setIsLoading(false);
      });

    console.log(scan_issues);
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchScanIssues(false);
      fetchShopNames();
      console.log('fetched Scan Issues');
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <InterStyled
        interStyle='H2'
        color='forestGreendefault'
        transform='Uppercase'
      >
        Scan Issues
      </InterStyled>
      {isLoading ? (
        <div style={{ alignSelf: 'center', padding: 300 }}>
          <InterStyled interStyle='H2' color='greytext'>
            {load_all ? 'Loading all data...' : 'Loading...'}
          </InterStyled>
        </div>
      ) : (
        <TableContainer plain='true' style={dataTableStyle}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <InterStyled interStyle='Label 1' color='greytext'>
                    Document ID
                  </InterStyled>
                </TableCell>
                <TableCell align='center'>
                  <InterStyled interStyle='Label 1' color='greytext'>
                    Label Image
                  </InterStyled>
                </TableCell>
                <TableCell align='center'>
                  <InterStyled interStyle='Label 1' color='greytext'>
                    Can read
                  </InterStyled>
                </TableCell>
                <TableCell align='center'>
                  {' '}
                  <InterStyled>Tracking number</InterStyled>
                </TableCell>
                <TableCell align='center'>
                  {' '}
                  <InterStyled>Shop name</InterStyled>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scan_issues.map((scanned, index) => {
                return (
                  <ScanIssuesList
                    data={scanned}
                    index={index}
                    key={index}
                    options={shop_options}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!load_all ? (
        <div
          style={{
            alignSelf: 'center',
            width: 300,
            textAlign: 'center',
          }}
        >
          <Button
            variant='contained'
            onClick={() => {
              fetchScanIssues(true);
            }}
          >
            Reload all data
          </Button>
        </div>
      ) : null}
    </Container>
  );
}
