import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import firebase from '../firebase';
import { toast } from 'react-toastify';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

const Container = styled.div`
  min-height: 93vh;
  background-color: #f5f5f5;
  padding: 40px;
  display: flex;
  justify-content: center;
`;

const ImageUploaderContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

function CreateVoucher() {
  /**
   * State Variables
   */

  const db = firebase.firestore();

  // UI Controlling Variables
  const [generalError, setGeneralError] = useState('');
  const [photoImageSrc, setPhotoImageSrc] = useState();
  const [voucherPartnersIDs, setVoucherPartnersIDs] = useState([]);

  // Error Controlling variables
  const [voucherIDError, setVoucherIDError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [formSubmitable, setFormSubmitable] = useState(true);

  // Get data on mount
  useEffect(() => {
    db.collection('VoucherPartners')
      .get()
      .then((querySnapshot) => {
        let tempPartnersIDs = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().id) {
            tempPartnersIDs.push(doc.data().id);
          }
        });
        setVoucherPartnersIDs([...tempPartnersIDs]);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  }, [db]);

  /**
   * Validation Functions
   */

  const validateVoucherID = (id) => {
    const valid = /^[a-z0-9]+$/.test(id);
    if (valid) {
      setVoucherIDError('');
      return true;
    } else if (!valid && id === '') {
      setVoucherIDError('Required');
      return false;
    } else {
      setVoucherIDError('Allowed Values are [a-z0-9] only');
      return false;
    }
  };

  const validateURL = (url) => {
    const valid = /^https:\/\//.test(url);
    if (valid) {
      setUrlError('');
      return true;
    } else if (!valid && url === '') {
      setUrlError('Required');
      return false;
    } else {
      setUrlError("Must start with 'https://'");
      return false;
    }
  };

  const validatePhoto = (info) => {
    if (info) {
      if (!info.type.match(/^(image\/jpeg|image\/png|image\/webp)$/)) {
        setGeneralError('Photo can only be of type jpeg, jpg, png or webp');
        return false;
      } else if (info.height !== 266 || info.width !== 400) {
        setGeneralError('Photo must be of size 400x266');
        return false;
      } else {
        setGeneralError('');
        return true;
      }
    } else {
      setGeneralError('Photo is Required, Please upload one');
      return false;
    }
  };

  const submitCreateVoucher = async (event) => {
    event.preventDefault();

    // Return if another submit is in place
    if (!formSubmitable) return;

    // Stop submitting forms until finished with this submition
    setFormSubmitable(false);

    // Validation
    if (!validateVoucherID(event.target.voucherid.value)) {
      setFormSubmitable(true);
      return;
    }
    if (!validateURL(event.target.url.value)) {
      setFormSubmitable(true);
      return;
    }
    if (!validatePhoto(photoImageSrc)) {
      setFormSubmitable(true);
      return;
    }

    console.log('Form Validation Passed');

    // Create Voucher object from the data
    const photo = await toBase64(event.target.photo.files[0]);
    const voucherObject = {
      id: event.target.voucherid.value,
      partnerid: event.target.partnerid_voucher.value,
      heading1: event.target.heading1.value,
      heading2: event.target.heading2.value,
      category: event.target.category.value,
      photo,
      keepoalas: parseFloat(event.target.keepoalas.value),
      value: {
        number: event.target.value_number.value,
        unit: event.target.value_unit.value,
      },
      neukunde: event.target.neukunde.checked,
      terms: event.target.terms.value,
      url: event.target.url.value,
      maximum_purchases_per_user: parseFloat(
        event.target.maximum_purchases_per_user.value,
      ),
      rating: parseInt(event.target.rating.value),
    };

    // Send the API REQUEST
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'application/json',
        Signature:
          'sha256=' +
          Base64.stringify(
            hmacSHA256(
              JSON.stringify(voucherObject),
              process.env.REACT_APP_CURRENT_SERVER_KEY,
            ),
          ),
      },
      body: JSON.stringify(voucherObject),
    };

    fetch(
      `${process.env.REACT_APP_REST_API_URL}/voucher_creation`,
      requestOptions,
    )
      .then((res) => {
        res.json().then((data) => {
          if (res.status === 200) {
            // Send a notification
            toast.success(data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            });
          } else {
            toast.error(data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            });
          }

          // Enable submitting the form again
          setFormSubmitable(true);
        });
      })
      .catch((err) => {
        console.error(err);
        // Send a notification
        toast.error(
          'Failed to Create Voucher, Check the console for the error details',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          },
        );

        // Enable submitting the form again
        setFormSubmitable(true);
      });
  };

  // Helper Functions
  const showImageInfo = (event, setSrcInfo) => {
    const file = event.target.files[0];

    if (file) {
      const type = file.type;
      const reader = new FileReader();

      reader.onloadend = function () {
        const img = new Image();
        img.src = reader.result; // Base64-encoded image
        img.onload = function () {
          setSrcInfo({
            src: reader.result,  // Use Base64 instead of objectUrl
            name: file.name,
            type,
            file,
            size: (file.size / (1024 * 1024))  // Convert size to MB
          });
        };
      };

      reader.readAsDataURL(file);  // Convert file to Base64 data URL
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Container>
      <Box sx={{ width: 800 }}>
        <Card variant='outlined' sx={{ padding: 3 }}>
          <Typography variant='h5' component='div' sx={{ textAlign: 'center' }}>
            Create Voucher
          </Typography>

          <form style={{ marginTop: '15px' }} onSubmit={submitCreateVoucher}>
            {/* Main Required */}
            <TextField
              label='Voucher ID'
              name='voucherid'
              required
              variant='filled'
              onChange={(event) => validateVoucherID(event.target.value)}
              fullWidth
              sx={{ marginBottom: 3 }}
              error={voucherIDError === '' ? false : true}
              helperText={voucherIDError}
            />
            <TextField
              label='Category'
              name='category'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label='Heading 1'
              name='heading1'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label='Heading 2'
              name='heading2'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label='Keepoalas'
              type='number'
              InputProps={{ inputProps: { min: 1 } }}
              name='keepoalas'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label='Maximum Purchases per User'
              InputProps={{ inputProps: { min: 1 } }}
              type='number'
              name='maximum_purchases_per_user'
              required
              variant='filled'
              fullWidth
              defaultValue={5}
              sx={{ marginBottom: 3 }}
              helperText={'How many of these a user can purchase'}
            />
            <TextField
              label='Voucher Rating'
              InputProps={{ inputProps: { min: 0, max: 10, step: 1 } }}
              defaultValue={0}
              type='number'
              name='rating'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
              helperText={
                'vouchers with higher numbers get shown infront. Vouchers are ranked 1st by ranking (high to low), 2nd by price (low to high)'
              }
            />

            <ImageUploaderContainer>
              <div>
                <Typography component='div'>Photo</Typography>

                <Button
                  variant='outlined'
                  component='label'
                  sx={{ margin: '10px' }}
                >
                  Upload
                  <input
                    label='Photo'
                    name='photo'
                    hidden
                    accept='image/*'
                    type='file'
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => showImageInfo(event, setPhotoImageSrc)}
                  />
                </Button>
              </div>
              {photoImageSrc && (
                <div>
                  <Card sx={{ marginLeft: 10 }}>
                    <img width='400' src={photoImageSrc.src} alt='voucher' />
                    <CardContent>
                      <Typography component='div'>
                        <span
                          style={{ fontWeight: 'bold', marginLeft: '10px' }}
                        >
                          Width:
                        </span>{' '}
                        {photoImageSrc.width}
                        <span
                          style={{ fontWeight: 'bold', marginLeft: '10px' }}
                        >
                          Height:
                        </span>{' '}
                        {photoImageSrc.height}
                        <span
                          style={{ fontWeight: 'bold', marginLeft: '10px' }}
                        >
                          Type:
                        </span>{' '}
                        {photoImageSrc.type}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              )}
            </ImageUploaderContainer>

            <TextField
              label='Terms'
              name='terms'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label='URL'
              name='url'
              variant='filled'
              required
              onChange={(event) => validateURL(event.target.value)}
              fullWidth
              sx={{ marginBottom: 3 }}
              error={urlError === '' ? false : true}
              helperText={urlError}
            />
            <FormControlLabel
              sx={{ marginBottom: 3, marginLeft: 0 }}
              control={<Checkbox name='neukunde' />}
              label='Neukunde'
            />
            <TextField
              label='Voucher Parnter ID'
              name='partnerid_voucher'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
              select
              defaultValue=''
            >
              {voucherPartnersIDs.map((partner) => {
                return (
                  <MenuItem key={partner} value={partner}>
                    {partner}
                  </MenuItem>
                );
              })}
            </TextField>

            {/* New Value Option */}
            <TextField
              defaultValue='EUR'
              label='Value Unit'
              name='value_unit'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
              select
            >
              <MenuItem value='EUR'>EURO</MenuItem>
              <MenuItem value='percent'>Percent</MenuItem>
              <MenuItem value='Freifahrt'>Freifahrt</MenuItem>
            </TextField>
            <TextField
              label='Value Number'
              type='number'
              name='value_number'
              required
              variant='filled'
              fullWidth
              sx={{ marginBottom: 3 }}
            />

            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              {/* Errors */}
              {generalError && <Alert severity='error'>{generalError}</Alert>}

              {/* Create Button */}
              <Button
                disabled={!formSubmitable}
                style={{ marginTop: '20px' }}
                variant='outlined'
                type='submit'
              >
                Create Voucher
              </Button>
            </div>
          </form>
        </Card>
      </Box>
    </Container>
  );
}

export default CreateVoucher;
