import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link as MuiLink } from '@mui/material';
import { AuthContext } from '../utils/providers/AuthProvider';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import ToolbarMui from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(MuiLink)`
  padding: 10px;
  color: #fff !important;
  text-decoration: none !important;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  &:hover {
    background-color: #0089d2;
  }
`;

const Toolbar = styled(ToolbarMui)`
  display: flex;
  justify-content: space-between;
`;

export default function Navbar() {
  const { user, handleSignOut } = useContext(AuthContext);

  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h5' component='div'>
            Keepoala Backend
          </Typography>

          <div style={{ height: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <Link component={RouterLink} to='/'>
              Home
            </Link>
            <Link component={RouterLink} to='/shoporders'>
              Shop Orders
            </Link>
            <Link component={RouterLink} to='/user'>
              User
            </Link>
            <Link component={RouterLink} to='/createvoucher'>
              Create Voucher
            </Link>
            <Link component={RouterLink} to='/createchallenge'>
              Create Challenge
            </Link>
            <Link component={RouterLink} to='/voucherpartner'>
              Voucher Partner
            </Link>
            <Link component={RouterLink} to='/pushnotifications'>
              Push Notifications
            </Link>
            <Link component={RouterLink} to='/sorrypoints'>
              Send Sorry Points
            </Link>
            <Link component={RouterLink} to='/sendvoucher'>
              Send Voucher
            </Link>
            <Link component={RouterLink} to='/orderimagesparser'>
              Order Images Parser
            </Link>
            <Link component={RouterLink} to='/scanissues'>
              Scan Issues
            </Link>
            <Link component={RouterLink} to='/reporting'>
              Reporting
            </Link>
            <Link onClick={handleSignOut} href='#'>
              Log Out
            </Link>
          </div>

          <div>
            <span style={{ fontWeight: 'bold' }}>Current User ID : </span>{' '}
            {user.uid}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
