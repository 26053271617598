import { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import firebase from "../firebase";
import { toast } from "react-toastify";
import InterStyled from "../styles/InterStyled";
import Colors from "../utils/helper/Colors";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

const Container = styled.div`
  min-height: 93vh;
  background-color: #f5f5f5;
  padding: 40px;
  display: flex;
  justify-content: center;
`;

function SorryPoints() {
  const db = firebase.firestore();
  // Error Controlling variables
  const [uidError, setUIDError] = useState("");

  const [form_submitable, setFormSubmitable] = useState(true);
  const [keepoalas_error, setKeepoalasError] = useState("");
  const [send_sorry_points_object, setSendSorryPointsObject] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateUID = (id) => {
    // TODO: Add to this the right regex if needed
    let valid = /^[A-Za-z/=0-9]+$/.test(id);
    if (valid) {
      setUIDError("");
      return true;
    } else if (!valid && id === "") {
      setUIDError("Required");
      return false;
    } else {
      setUIDError("Allowed Values are [a-zA-Z0-9/=] only");
      return false;
    }
  };
  const validateKeepoalas = (id) => {
    // TODO: Add to this the right regex if needed
    let valid = /^[0-9]+$/.test(id);
    if (valid) {
      setKeepoalasError("");
      return true;
    } else if (!valid && id === "") {
      setKeepoalasError("Required");
      return false;
    } else {
      setKeepoalasError("Allowed Values are [0-9] only");
      return false;
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const uid = event.target.uid.value;
    const keepoalas = parseInt(event.target.keepoalas.value);
    if (!form_submitable) return;

    // Stop submitting forms until finished with this submition
    setFormSubmitable(false);

    if (!validateUID(uid)) {
      console.log("uid is invalid");
      setFormSubmitable(true);
      return;
    }
    if (!validateKeepoalas(keepoalas)) {
      console.log("keepoalas are invalid");
      setFormSubmitable(true);
      return;
    }

    const sorry_points_object = {
      lang: "de",
      user_id: uid,
      dict: {
        user_id: uid,
        heading1: "Sorry",
        heading2: "sorry",
        keepoalas: keepoalas,
        partnerid: "keepoala",
        lang: "de",
      },
    };
    setSendSorryPointsObject(sorry_points_object);
    console.log(sorry_points_object)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Enable submitting the form again
    setFormSubmitable(true);
    console.log("send sorry points object", send_sorry_points_object);
  };

  const handleAgree = () => {
    setLoading(true);
    if (send_sorry_points_object !== {}) {
      console.log("agreed");
      db.collection("VoucherOrder")
        .add(send_sorry_points_object)
        .then(() => {
          toast.success("Sorry Points were sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        })
        .catch((err) => {
          console.error(err);
          // Send a notification
          toast.error(
            "Failed to send sorry points, Check the console for the error details",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
      handleClose();
      return;
    }
    console.log("disagreed");
    handleClose();
  };
  return (
    <Container>
      <Box sx={{ width: 800 }}>
        <Card variant="outlined" sx={{ padding: 3 }}>
          <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
            Send Sorry Points
          </Typography>
          <form style={{ marginTop: "15px" }} onSubmit={handleSubmit}>
            <TextField
              label="UID"
              name="uid"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 3 }}
              helperText={uidError}
              error={uidError === "" ? false : true}
              onChange={(event) => validateUID(event.target.value)}
              multiline
              minRows={3}
            />
            <TextField
              label="Keepoalas"
              name="keepoalas"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 3 }}
              helperText={keepoalas_error}
              error={keepoalas_error === "" ? false : true}
              onChange={(event) => validateKeepoalas(event.target.value)}
              multiline
              minRows={3}
            />
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                flex: "display",
                flexDirection: "column",
              }}
            >
              {/* Create Button */}
              <div style={{ paddingBottom: 10 }}>
                <Button
                  disabled={!form_submitable || loading}
                  style={{ marginTop: "20px" }}
                  variant="outlined"
                  type="submit"
                >
                  {loading ? (
                    <div
                      style={{
                        marginRight: 60,
                        marginLeft: 60,
                      }}
                    >
                      <CircularProgress size={20} thickness={5} />
                    </div>
                  ) : (
                    "Send Points"
                  )}
                </Button>
              </div>
              {loading ? (
                <InterStyled
                  interStyle="Label2"
                  style={{
                    color: Colors.keepoala.greytext,
                    textAlign: "center",
                  }}
                >
                  Loading..
                </InterStyled>
              ) : null}
            </div>
          </form>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Send Notification with these details?"}
          </DialogTitle>
          {!loading ? (
            <div>
              {" "}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <b>uid: </b>
                  {send_sorry_points_object?.user_id ?? 0} <br />
                  <b>keepoalas: </b>
                  {send_sorry_points_object?.dict?.keepoalas ?? 0} <br />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleAgree} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </div>
          ) : (
            <div
              style={{
                alignSelf: "center",
                padding: 20,
              }}
            >
              <CircularProgress size={50} thickness={3} />

              <div>
                <InterStyled
                  interStyle="Label 1"
                  style={{ color: Colors.keepoala.forestGreendefault }}
                >
                  Loading details...
                </InterStyled>
              </div>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
              </DialogActions>
            </div>
          )}
        </Dialog>
      </Box>
    </Container>
  );
}

export default SorryPoints;
