import React from "react";
import styled from "styled-components";
import Image from "react-image-enlarger";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/**
 * @description Form to fill in the Scanned order data
 *
 * @component
 *
 * @author youssef
 */

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 48%;
  border-radius: 30px;
`;

export default function ScanIssueForm({
  image,
  setShopName,
  options,
  shop_name,
  tracking_number,
  validateTrackingNumber,
  handleClose,
  onSubmit,
  disableSave,
  can_read,
  setCanRead,
  checkTrackingNumberLength
}) {
  const [zoomed, setZoomed] = React.useState(false);
  return (
    <Container>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div style={{ alignContent: "center", paddingRight: "10px" }}>
          <Image
            style={{ width: "500px", height: "auto", borderRadius: "25px" }}
            src={image}
            zoomed={zoomed}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
          />
        </div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            marginLeft: "50px",
            paddingTop: 30,
          }}
        >
          <Autocomplete
            style={{ marginBottom: "20px" }}
            freeSolo
            id="free=-solo-box"
            options={options}
            inputValue={shop_name.label}
            onChange={(_, value) => {
              // if it's a valid option then set it
              if (value !== null) {
                setShopName(value);
              }
            }}
            onInputChange={(_, newInputValue) => {
              // if it doesn't exists in the options list, make it the name
              if (!options.some((el) => el.label === newInputValue)) {
                console.log("it doesn't exists");
                setShopName({ label: newInputValue, name: newInputValue });
              } else {
                const value = options.filter((e) => e.label === newInputValue);
                setShopName(value[0]);
              }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Shop"
                name="shop_text_field"
                required
                variant="outlined"
                fullWidth
                error={shop_name.name === ""}
                helperText={"Enter shop's name (New names are allowed)"}
                minRows={1}
              />
            )}
          />
          <TextField
            style={{ marginBottom: "20px" }}
            label="Tracking Number"
            name="tracking_number"
            required
            defaultValue={tracking_number}
            variant="outlined"
            fullWidth
            helperText={"Add a valid tracking number"}
            error={!checkTrackingNumberLength(tracking_number)}
            onChange={validateTrackingNumber}
            minRows={1}
            sx={{ width: 300 }}
          />
          <FormControlLabel
          control={
            <Checkbox
              name="can_read_checkbox"
              onChange={(event) => {
                setCanRead(event.target.checked);
              }}
              checked={can_read}
            />
          }
          label="Can read"
        />
          <div style={{ textAlign: "center", alignSelf: "center" }}>
            {/* Create Button */}
            <Button
              disabled={disableSave}
              style={{ marginBottom: 16, marginBottom: "20px" }}
              variant="outlined"
              type="submit"
              onClick={onSubmit}
            >
              Save Changes
            </Button>
          </div>
          <Button variant="contained" onClick={handleClose}>
            close
          </Button>
        </div>
      </div>
    </Container>
  );
}
