import React from 'react';
import InterText from './InterText';
import { Button } from '@mui/material';

// Types of the components provided
export type interStyleType =
  | 'MenuLink'
  | 'Label 1'
  | 'Label1'
  | 'Label2'
  | 'H2'
  | 'H3'
  | 'P1'
  | 'P2'
  | 'P3'
  | 'Button'
  | 'DisabledButton'
  | 'WideButtonDark'
  | 'WideButton';

// Props to be passed for choosing and customizing the componenet
type InterStyledProps = {
  children: Element | string;
  className?: any;
  interStyle?: interStyleType;
  style?: any;
  onClick?: Function;
};

// Default Prop for the component
InterStyled.defaultProps = {
  interStyle: 'Label 1',
};

/**
 * Styled component for different styles of inter font
 * @param props
 *
 * @returns
 */
export default function InterStyled(props: InterStyledProps) {
  const { children, onClick, interStyle, style, className, ...otherProps } =
    props;

  switch (interStyle) {
    case 'MenuLink':
      return (
        <InterText
          size='m'
          color='greytext'
          type='medium'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'Label 1':
      return (
        <InterText
          size='m'
          type='semi-bold'
          color='greytext'
          transform='uppercase'
          style={{ ...style, letterSpacing: 0.2 }}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'Label1':
      return (
        <InterText
          size='s'
          type='bold'
          color='forestGreendefault'
          transform='uppercase'
          style={{ ...style, letterSpacing: 0.2 }}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'Label2':
      return (
        <InterText
          size='xs'
          type='bold'
          color='greytext'
          transform='uppercase'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'P1':
      return (
        <InterText size='s' style={style} className={className} {...otherProps}>
          {children}
        </InterText>
      );
    case 'P2':
      return (
        <InterText
          size='xs'
          type='regular'
          color='greydefault'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'P3':
      return (
        <InterText
          size='xsl'
          type='regular'
          color='greytext'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'H2':
      return (
        <InterText
          size='xl'
          type='bold'
          color='mediumgrey'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'H3':
      return (
        <InterText
          size='m'
          type='semi-bold'
          color='greytext'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
    case 'Button':
      return (
        <Button
          style={{ padding: '0px', minWidth: '40px' }}
          onClick={() => {
            if (onClick !== undefined) onClick();
          }}
        >
          <InterText
            size='m'
            color='greytext'
            type='medium'
            style={style}
            className={className}
            {...otherProps}
          >
            {children}
          </InterText>
        </Button>
      );
    case 'DisabledButton':
      return (
        <Button style={{ padding: '0px', minWidth: '40px' }} disabled>
          <InterText
            size='m'
            color='greydefault'
            type='medium'
            style={style}
            className={className}
            {...otherProps}
          >
            {children}
          </InterText>
        </Button>
      );
    default:
      return (
        <InterText
          size='m'
          type='bold'
          style={style}
          className={className}
          {...otherProps}
        >
          {children}
        </InterText>
      );
  }
}
