import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { createReadibleDate, createReadibleTime } from '../views/OrderListView';
import styled from 'styled-components';

const AccordionDetailsSpecial = styled(AccordionDetails)`
  flex-direction: column;
`;
function subitem_span(name, data) {
  return (
    <span key={name}>
      <b>{name}:&nbsp;</b>
      {data}
      <br />
    </span>
  );
}
export default function ShopOrderListRow(props) {
  return (
    <TableRow key={props.data.key}>
      {props.header.map((key, index) => {
        if (props.needs_expansion.includes(key)) {
          return (
            <TableCell key={index}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <b>
                    {key}
                    {props.data[key] !== undefined &&
                    props.data[key] !== null &&
                    props.data[key].length !== undefined
                      ? ' (' + props.data[key].length + ')'
                      : ''}
                  </b>
                </AccordionSummary>
                <AccordionDetailsSpecial>
                  {props.data[key] !== undefined &&
                  props.data[key] !== null &&
                  typeof props.data[key] === 'object' &&
                  Object.keys(props.data[key])[0] !== undefined &&
                  Object.keys(props.data[key])[0] === 'email'
                    ? // email
                      Object.keys(props.data[key]).map((subitem_key) =>
                        subitem_span(subitem_key, props.data[key][subitem_key]),
                      )
                    : // line items or Shopify Shipping price set
                    props.data[key] !== undefined &&
                      props.data[key] !== null &&
                      typeof props.data[key] === 'object'
                    ? Object.keys(props.data[key]).map(
                        (subitem_key, outerIndex) => {
                          return (
                            <div key={outerIndex}>
                              <b>{subitem_key}</b>
                              <br />
                              {props.data[key][subitem_key] !== undefined &&
                              props.data[key][subitem_key] !== null &&
                              typeof props.data[key][subitem_key] === 'object'
                                ? Object.keys(props.data[key][subitem_key]).map(
                                    (sub_subitem_key, innerIndex) =>
                                      subitem_span(
                                        sub_subitem_key,
                                        props.data[key][subitem_key][
                                          sub_subitem_key
                                        ],
                                      ),
                                  )
                                : props.data[key][subitem_key] !== undefined &&
                                  props.data[key][subitem_key] !== null
                                ? props.data[key][subitem_key].toString()
                                : ''}
                              <hr />
                            </div>
                          );
                        },
                      )
                    : props.data[key] !== undefined && props.data[key] !== null
                    ? props.data[key].toString()
                    : ''}
                </AccordionDetailsSpecial>
              </Accordion>
            </TableCell>
          );
        } else {
          if (props.is_date.includes(key)) {
            return (
              <TableCell key={index}>
                {' '}
                {createReadibleDate(
                  new Date(parseFloat(props.data[key]) * 1000),
                ) +
                  ' ' +
                  createReadibleTime(
                    new Date(parseFloat(props.data[key]) * 1000),
                  )}
              </TableCell>
            );
          } else {
            return (
              <TableCell key={index}>
                {' '}
                {props.data[key] !== undefined &&
                props.data[key] !== null &&
                typeof props.data[key] === 'object'
                  ? Object.keys(props.data[key]).join(' ')
                  : props.data[key] !== undefined && props.data[key] !== null
                  ? props.data[key].toString()
                  : ''}{' '}
              </TableCell>
            );
          }
        }
      })}
    </TableRow>
  );
}
